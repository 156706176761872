<template>
    <v-container>
      <v-snackbar
        :color="color"
        :timeout="3000"
        top
        multi-line
        v-model="snackbar"
        >
            {{text}}
        </v-snackbar>
        <v-alert
          text
          type="success"
          v-if="alert"
        >
          <v-row>
            <v-col class="grow">{{text}}</v-col>
          </v-row>
        </v-alert>
        <v-row justify="center" align="center">
            <v-col cols="12" sm="9" md="6">
                <p class="body-1 font-weight-regular secondary--text">An email with a verification code has been sent to you. Please verify your email.</p>
                <v-form
                ref="form"
                v-model="valid"
                v-on:submit.prevent
                >
                    <v-text-field
                    v-model="code"
                    label="Enter the verification code"
                    :rules="[v => !!v || 'You must enter the verification code', v => v.length >= 4 || 'Code must be more than 4 digits']"
                    class="remove-controls"
                    color="secondary"
                    id="verify-code"
                    ></v-text-field>
                    <v-btn type="submit" color="secondary" tile block @click="verifyEmail" :disabled="!valid" :loading="loading">Verify</v-btn>
                    <v-btn color="blue" :disabled="resending" text @click="resend">Resend verification code</v-btn>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { resend, verify, headersNoAuth } from '../links'

export default {
  title: '-Email Verification',
  data () {
    return {
      code: '',
      valid: true,
      alert: false,
      resending: false,
      snackbar: false,
      color: '',
      text: '',
      loading: false
    }
  },
  methods: {
    verifyEmail () {
      this.loading = true
      this.$refs.form.validate()
      if (this.valid) {
        const body = {
          email: this.$route.params.email,
          code: this.code
        }
        this.$http.post(verify, body, { headers: headersNoAuth() }).then(response => {
          if (response.body.status === false) {
            this.snackbar = true
            this.color = 'error'
            this.text = response.body.message
            this.loading = false
          } else {
            if (response.body.data.token) {
              this.$store.dispatch('setUser', response.body.data.user)
              localStorage.setItem('userId', response.body.data.user.id)
              localStorage.setItem('atsUser', JSON.stringify(response.body.data.user))
              this.$cookies.set('userToken', response.body.data.token.access_token, response.body.data.token.expire_in)
              setTimeout(() => {
                if (!this.$cookies.isKey('userToken')) {
                  this.$store.dispatch('removeUser')
                  this.$cookies.remove('userToken')
                  localStorage.removeItem('userId')
                }
              }, response.body.data.token.expire_in * 1000)
              this.loading = false
              this.alert = true
              this.text = ' Your E-mail has been verified, you will be redirected to our home page'
              setTimeout(() => {
                this.$router.push({ name: 'Home' })
              }, 3000)
            } else {
              this.loading = false
              this.alert = true
              this.text = ' Your E-mail has been verified, you will be redirected to our login page'
              setTimeout(() => {
                this.$router.push({ name: 'login' })
              }, 3000)
            }
          }
        })
      }
    },
    resend () {
      this.resending = true
      this.$http.post(resend, { email: this.$route.params.email }, { headers: headersNoAuth() }).then(response => {
        this.resending = false
        this.snackbar = true
        this.color = 'success'
        this.text = response.body.message
      })
    }
  },
  created () {
    if (!this.$route.params.email) this.$router.push({ name: 'Home' })
  }
}
</script>
