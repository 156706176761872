const endPoint = process.env.NODE_ENV === 'development' ? 'https://dev.atnbookingengine.com/api' : 'https://www.atnbookingengine.com/api'
const endPointV2 = process.env.NODE_ENV === 'development' ? 'https://dev.atnbookingengine.com/v2/api' : 'https://www.atnbookingengine.com/v2/api'

// app log
export const appLog = `${endPoint}/applicationLog`

// app currency
export const currencies = `${endPoint}/getCurrencies`

// device token for notification
export const deviceToken = `${endPoint}/user/device-token`

// trips
export const homeTrips = `${endPoint}/trips-in-home/8`
export const trips = `${endPoint}/trips`
export const bookTrip = `${endPointV2}/trips/request-to-book`
export const allCities = `${endPointV2}/tours/list-city`

// airlines
export const getAirlines = (term) => `${endPointV2}/flights/airlines/autocomplete?term=${term}`
export const getAirports = (term) => `${endPoint}/airport/autocomplete?term=${term}`
export const searchFlights = (term) => `${endPoint}/flights/search?${term}`
export const getFlight = (id, search, relatedFlightId) => {
  if (relatedFlightId) return `${endPoint}/flights?flight_id=${id}&search_id=${search}&related_flight_id=${relatedFlightId}`
  else return `${endPoint}/flights?flight_id=${id}&search_id=${search}`
}
export const checkFlight = `${endPoint}/flightAvailability`
export const getFlightPromotions = `${endPoint}/flights/promotions`
export const createPNR = `${endPoint}/pnr/create`
export const showPNR = (pnr) => `${endPoint}/pnr/${pnr}`
export const updatePassportInfo = `${endPoint}/user/subAgent/flights/booking/update-passport-info`
export const addCreditCard = `${endPoint}/user/subAgent/flights/booking/add-credit-card-info`
export const bookFlightByMail = `${endPointV2}/flights/send-special-offer-request-mail`
export const flightRules = (id) => `${endPoint}/flights/rules?flight_id=${id}`

// user data
export const register = `${endPoint}/user/register`
export const profile = (host) => `${endPoint}/user/profile${host}`
export const userBookings = `${endPoint}/user/bookings`
export const userUpdate = `${endPoint}/user/update`
export const updateNotification = `${endPoint}/user/updateNotification`
export const login = `${endPoint}/user/login`
export const logout = `${endPoint}/user/logout`
export const collectPoints = `${endPoint}/user/collect-points-history`
export const redeem = `${endPoint}/user/redeem-points-history`
export const cancelFlight = `${endPoint}/user/bookings/cancel-flight`
export const mangeAgentEmails = `${endPoint}/user/subAgent/manage-emails`
export const issueRequest = `${endPoint}/user/subAgent/send-request-to-issue-ticket`
export const changePassword = `${endPoint}/user/change-password`

// auth
export const verify = `${endPoint}/email/verify`
export const resend = `${endPoint}/email/resend`
export const resetPasswordCode = `${endPoint}/reset-password-code`
export const resetPassword = `${endPoint}/reset-password`
export const approveEmail = (token) => `${endPoint}/approveUser?token=${token}`

// cities
export const getCities = (term) => `${endPointV2}/cities/autocomplete?term=${term}`
export const getCitiesByCode = (code) => `${endPointV2}/cities/autocomplete?code=${code}&term=''`
export const searchHotels = (term) => `${endPointV2}/hotels/search?${term}`
export const getHotel = (sessionId, hotelIndex, hotelCode) => `${endPointV2}/hotels/show?sessionId=${sessionId}&hotelIndex=${hotelIndex}&hotelCode=${hotelCode}`
export const hotelAvailability = (term) => `${endPointV2}/hotels/room/availability?${term}`
export const bookHotel = `${endPointV2}/hotels/book`
export const holdRooms = `${endPointV2}/hotels/hold-booking`

// cars
export const carsSearch = (term) => `${endPoint}/cars/search?${term}`
export const showCar = (term) => `${endPoint}/cars/show?${term}`
export const rentCar = `${endPoint}/cars/book`

// offices
export const offices = `${endPoint}/offices/list`

// group pnrs
export const groupPnrs = `${endPointV2}/contract/pnrs/get-all-pnrs`
export const reservedPnrs = `${endPointV2}/contract/pnrs/get-reserved-pnrs`
export const archivedPnrs = `${endPointV2}/contract/pnrs/get-archived-pnrs`
export const createPnr = `${endPointV2}/contract/pnrs/store`
export const removePnr = (id) => `${endPointV2}/contract/pnrs/remove/${id}`
export const getPnr = (id) => `${endPointV2}/contract/pnrs/pnr/${id}`
export const bookPnr = (id) => `${endPointV2}/contract/pnrs/booking/${id}`
export const updatePnrStatus = (id) => `${endPointV2}/contract/pnrs/change-status/${id}`

// contracts
export const contractAirlines = `${endPointV2}/contract/airlines`
export const lastUpdatedContracts = `${endPointV2}/contract/last-updated-contracts`
export const contractRegions = `${endPointV2}/contract/regions`
export const storeContract = `${endPointV2}/contract/store`
export const pulishedContracts = (airlineCode) => `${endPointV2}/contract/airline/${airlineCode}/published-contracts`
export const contractDetails = (id) => `${endPointV2}/contract/show-contract/${id}`
export const deleteContract = (id) => `${endPointV2}/contract/deleted-contracts/${id}`
export const restoreDeleteContract = (id) => `${endPointV2}/contract/restore-deleted-contract/${id}`
export const updateContract = (id) => `${endPointV2}/contract/${id}/update`
export const wbContract = `${endPointV2}/contract/wb`
export const expiredContracts = (id) => `${endPointV2}/contract/airline/${id}/expired-contracts`
export const contractHistory = (id, date) => `${endPointV2}/contract/${id}/histories?date=${date}`
export const contractDOT = (airlineCode) => `${endPointV2}/contract/dot/${airlineCode}`

// waivers
export const waiversAirlines = `${endPointV2}/contract/waivers/airlines`
export const storeWaiver = `${endPointV2}/contract/waivers/store`
export const getWaivers = (id) => `${endPointV2}/contract/waivers/list-by-airline/${id}`

// Commission CAP
export const weeks = `${endPointV2}/contract/get/weeks-data`
export const cap = (id) => `${endPointV2}/contract/commission-cap/${id}`

// contact us
export const contact = `${endPointV2}/contract/contact-us`

// headers
export function headers (token) {
  return { Authorization: 'Bearer ' + token, Accept: 'application/json', 'Content-type': 'application/json' }
}
export function headersNoAuth () {
  if (window.$cookies.isKey('userToken')) return { Authorization: 'Bearer ' + window.$cookies.get('userToken'), Accept: 'application/json', 'Content-type': 'application/json' }
  else return { Accept: 'application/json', 'Content-type': 'application/json' }
}

// requests

export const getSpecialRequests = `${endPointV2}/flights/special-requests`
