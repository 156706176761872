<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col cols="12" md="6">
                <h2 class="text-uppercase mb-2">{{page.replaceAll('-', ' ')}}</h2>
                <div v-if="page === 'airfares'">
                    <p>ATS provides High commissions and low net fares on 50+ carriers coupled with rock bottom net rates on over 200,000 hotels worldwide. Our website offers you ease of use with comprehensive platform, we'll help you make money and look like a star.</p>
                    <p>Get full access to our pricing via GDS s, add your markup and sell – you also will have full access to your PNR's. Our booking engine provides you with 24/7, three hundred and sixty-five days a year access to international net and commission fares.</p>
                </div>
                <div v-else-if="page === 'hotels'">
                    <p>ATS offers discounted fares for hotel rooms in nearly every major city and resort destination worldwide. ATS platform enable You or your client to save significant amounts of money on all type of hotel and room categories.</p>
                </div>
                <div v-else-if="page === 'group-travel'">
                    <p>For any group of 10 passengers or more, ATS will be able to assist you in securing best group rates to any destinations domestically or internationally.</p>
                    <p>With more than 28 years’ experience in assisting partners and organizing large religious pilgrimage group, ATS became a very well recognized name with all airlines partners.</p>
                </div>
                <div v-else-if="page === 'vacation-packages'">
                    <p>ATS helps you find the perfect vacation to popular, exotic and exclusive destinations around the world.</p>
                    <p>Our partners get the advantage of established land package rates combined with our competitive airfares which makes for a very well-priced experience for your customers.</p>
                    <p>ATS provide affordable flights on 50+ carriers to local and international destinations, we can help you fly all types of customers to exotic locations, islands, Europe, Africa or the far East along with well-planned packages served by experienced local tour operators.</p>
                    <p>Make this your one stop shop for virtually any type of travel program you wish to have or provide.</p>
                </div>
                <div v-else-if="page === 'booking-engine'">
                    <p>ATS platform functions efficiently and cost effectively. It allows all vendor products to come together seamlessly.</p>
                    <p>Initially focused on air transactions then expanded to focus on other categories that includes offering hotels, cruise lines and land packages all around the globe.</p>
                    <p>Our Booking Engine is Mobile Friendly and includes but not limited to the following features:</p>
                    <div class="d-flex flex-wrap">
                        <ul class="w-50">
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Private and Published commissionable fares</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> White Label B2B2C</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Exclusive discounts</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Unique itineraries</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Dynamic pricing</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Comprehensive analytics</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Dynamic packaging</li>
                        </ul>
                        <ul class="w-50">
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Discounts on 50+ Major Airlines</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Customizable to your Brand</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Branded itineraries</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Hotel Engine with net rates</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Multiple GDS capabilities</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Automated ticketing</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Easy product loading and management</li>
                            <li><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Advanced markup</li>
                        </ul>
                    </div>
                </div>
                <div v-else>
                    <ul>
                        <li>
                            <h3><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Travel Booking Engine:</h3>
                            <p>Providing assistance to integrate ATS booking engine into your own current website.</p>
                        </li>
                        <li>
                            <h3><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Branding & Social Media Exposure:</h3>
                            <p>We assist you to develop a brand If you don’t have it yet. We offer strategic planning to utilize on social media exposure and marketing.</p>
                        </li>
                        <li>
                            <h3><v-icon x-small color="orange">mdi-chevron-right-circle</v-icon> Website Building and Integration:</h3>
                            <p>Setting up a Basic up to Fully functioning website for your business.</p>
                        </li>
                    </ul>
                </div>
                <v-hover v-slot="{ hover }">
                    <v-btn v-if="!$store.state.user" x-large elevation="5" :color="hover ? 'blueDark' : 'orange'" class="white--text font-weight-bold d-block mt-5 btn-trans" tile @click="$router.push('/register')">Start with us now!</v-btn>
                </v-hover>
            </v-col>
            <v-col cols="12" md="6">
                <v-img v-if="page === 'airfares'" src="../assets/slider/1.jpeg"></v-img>
                <v-img v-else-if="page === 'hotels'" src="../assets/slider/3.jpeg"></v-img>
                <v-img v-else-if="page === 'group-travel'" src="../assets/slider/2.jpeg"></v-img>
                <v-img v-else-if="page === 'vacation-packages'" src="../assets/slider/4.jpeg"></v-img>
                <v-img v-else-if="page === 'booking-engine'" src="../assets/chart.jpeg"></v-img>
                <v-img v-else src="../assets/marketing.jpeg"></v-img>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  props: ['page']
}
</script>

<style>
.w-50 {
    width: 50%;
}
ul {
    list-style-type: none;
}
</style>
