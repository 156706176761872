<template>
    <v-container>
      <div class="d-flex flex-wrap justify-space-between">
        <h1 class="blueDark--text mb-5">Airline Code: {{$route.params.id}}</h1>
        <div>
          <v-btn :to="{name: 'netContract', params: {id: $route.params.id}}" class="mt-3" text color="blueLight"><v-icon left>mdi-file-document-edit</v-icon> Net Contracts</v-btn>
          <v-btn :to="{name: 'deletedContracts', params: {id: $route.params.id}}" class="mt-3" text color="blueLight"><v-icon left>mdi-file-document-remove</v-icon> Deleted Contracts</v-btn>
          <v-btn :to="{name: 'expiredContracts', params: {id: $route.params.id}}" class="mt-3" text color="blueLight"><v-icon left>mdi-file-clock</v-icon> Expired Contracts</v-btn>
          <v-btn :to="{name: 'contractDOT', params: {id: $route.params.id}}" class="mt-3" text color="blueLight"><v-icon left>mdi-file-clock</v-icon> DOT</v-btn>
        </div>
      </div>

      <div v-if="!loadingContracts && contracts.length">
        <contracts-table :contracts="contracts"></contracts-table>
      </div>

      <div v-else-if="!loadingContracts && !contracts.length">
          <p class="headline my-10 text-center"><v-icon color="warning">mdi-alert</v-icon> No contracts found!</p>
      </div>
    </v-container>
</template>

<script>
import { pulishedContracts, headers } from '../../links'
import contractsTable from '@/components/contracts/contractsTable'

export default {
  components: {
    contractsTable
  },
  data () {
    return {
      contracts: [],
      loadingContracts: true
    }
  },
  created () {
    this.$store.dispatch('setLoadingState', true)
    this.$http.get(pulishedContracts(this.$route.params.id), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      this.contracts = response.body.data
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.loadingContracts = false
      this.$store.dispatch('setLoadingState', false)
    })
  }
}
</script>
