import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)
// let user = null
// if (localStorage.getItem('atsUser')) user = JSON.parse(localStorage.getItem('atsUser'))
// else user = store.state.user
// if (store.state.user) user = store.state.user
// else if (localStorage.getItem('atsUser')) user = JSON.parse(localStorage.getItem('atsUser'))

// console.log(user.role)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "home" */ '../views/privacy.vue')
  },
  {
    path: '/terms&conditions',
    name: 'terms',
    component: () => import(/* webpackChunkName: "home" */ '../views/terms.vue')
  },
  {
    path: '/flights',
    name: 'flights',
    component: () => import(/* webpackChunkName: "flights" */ '../views/flights.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/flightResults',
    name: 'flightResults',
    component: () => import(/* webpackChunkName: "flightsResults" */ '../views/flightResults.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/book/:id/:search/:relatedFlightId',
    name: 'book',
    component: () => import(/* webpackChunkName: "flightsBook" */ '../views/book.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import(/* webpackChunkName: "verify" */ '../views/verify.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "login" */ '../views/register.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/resetPassword.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile.vue')
  },
  // {
  //   path: '/hotelsResults',
  //   name: 'hotelsResults',
  //   component: () => import(/* webpackChunkName: "hotelsResults" */ '../views/hotelsResults.vue'),
  //   beforeEnter: (to, from, next) => {
  //     if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
  //     else next()
  //   }
  // },
  // {
  //   path: '/hotels',
  //   name: 'hotels',
  //   component: () => import(/* webpackChunkName: "hotels" */ '../views/hotels.vue'),
  //   beforeEnter: (to, from, next) => {
  //     if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
  //     else next()
  //   }
  // },
  // {
  //   path: '/hotel/:sessionId/:hotelIndex/:hotelCode',
  //   name: 'oneHotel',
  //   component: () => import(/* webpackChunkName: "hotelDetails" */ '../views/oneHotel.vue'),
  //   beforeEnter: (to, from, next) => {
  //     if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
  //     else next()
  //   }
  // },
  // {
  //   path: '/bookhotel',
  //   name: 'hotelBook',
  //   component: () => import(/* webpackChunkName: "hotelBook" */ '../views/hotelBook.vue'),
  //   beforeEnter: (to, from, next) => {
  //     if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
  //     else next()
  //   }
  // },
  {
    path: '/cars',
    name: 'cars',
    component: () => import(/* webpackChunkName: "cars" */ '../views/cars.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/carsResults',
    name: 'carsResults',
    component: () => import(/* webpackChunkName: "carsResults" */ '../views/carsResults.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/carBook/:searchId/:carId',
    name: 'carsBook',
    component: () => import(/* webpackChunkName: "carsBook" */ '../views/carBook.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/trips',
    name: 'trips',
    component: () => import(/* webpackChunkName: "trips" */ '../views/trips.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/trip',
    name: 'trip',
    component: () => import(/* webpackChunkName: "trip" */ '../views/trip.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-make-bookings')) next('/unAuthorized')
      else next()
    }
  },
  // group pnr
  {
    path: '/group-pnr',
    name: 'groupPnr',
    component: () => import(/* webpackChunkName: "trips" */ '../views/groupPnr/list.vue')
    // beforeEnter: (to, from, next) => {
    //   if (!localStorage.getItem('token')) next('/unAuthorized')
    //   else next()
    // }
  },
  {
    path: '/group-pnr/:id',
    name: 'pnrDetails',
    component: () => import(/* webpackChunkName: "trips" */ '../views/groupPnr/pnr.vue')
    // beforeEnter: (to, from, next) => {
    //   if (!localStorage.getItem('token')) next('/unAuthorized')
    //   else next()
    // }
  },

  {
    path: '/approve',
    name: 'approve',
    component: () => import(/* webpackChunkName: "approve" */ '../views/approve.vue')
  },
  // contracts
  {
    path: '/contracts',
    name: 'contracts',
    component: () => import(/* webpackChunkName: "contracts" */ '../views/contracts/contracts.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/contracts/:id',
    name: 'contract',
    component: () => import(/* webpackChunkName: "contract" */ '../views/contracts/contract.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/contract/add',
    name: 'addContract',
    component: () => import(/* webpackChunkName: "addContract" */ '../views/contracts/form.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && store.state.user.role !== 'ats_admin') next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/contract/edit/:id',
    name: 'editContract',
    component: () => import(/* webpackChunkName: "editContract" */ '../views/contracts/form.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && store.state.user.role !== 'ats_admin') next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/contract/:id',
    name: 'contractDetails',
    component: () => import(/* webpackChunkName: "contract" */ '../views/contracts/details.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/contract/net/:id',
    name: 'netContract',
    component: () => import(/* webpackChunkName: "netContract" */ '../views/contracts/netContract.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/contract/DOT/:id',
    name: 'contractDOT',
    component: () => import(/* webpackChunkName: "netContract" */ '../views/contracts/contractDOT.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/contract/deleted/:id',
    name: 'deletedContracts',
    component: () => import(/* webpackChunkName: "deletedContract" */ '../views/contracts/deleted.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/contract/expired/:id',
    name: 'expiredContracts',
    component: () => import(/* webpackChunkName: "expiredContract" */ '../views/contracts/expired.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/contracts/airlnes/WB',
    name: 'wbContracts',
    component: () => import(/* webpackChunkName: "wbContracts" */ '../views/contracts/wb.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/contract/history/:id',
    name: 'contractHistory',
    component: () => import(/* webpackChunkName: "historyContracts" */ '../views/contracts/history.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },

  // waivers contracts
  {
    path: '/waivers',
    name: 'waivers',
    component: () => import(/* webpackChunkName: "waivers" */ '../views/contracts/waivers/airlines.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/waivers/:id',
    name: 'waiver',
    component: () => import(/* webpackChunkName: "waiver" */ '../views/contracts/waivers/waiver.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/waivers/waiver/show',
    name: 'waiverShow',
    component: () => import(/* webpackChunkName: "waiverShow" */ '../views/contracts/waivers/waiverFile.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/waivers/waiver/add',
    name: 'addWaiver',
    component: () => import(/* webpackChunkName: "addWaivers" */ '../views/contracts/waivers/add.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && store.state.user.role !== 'ats_admin') next('/unAuthorized')
      else next()
    }
  },

  // caps
  {
    path: '/commissions/cap',
    name: 'caps',
    component: () => import(/* webpackChunkName: "caps" */ '../views/contracts/cap/list.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },
  {
    path: '/commissions/cap/:id',
    name: 'cap',
    component: () => import(/* webpackChunkName: "caps" */ '../views/contracts/cap/cap.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') && (store.state.user.role === 'subAgent' || store.state.user.role === 'ats_subAgent') && !store.state.user.permissions.includes('can-see-contracts')) next('/unAuthorized')
      else next()
    }
  },

  // about
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/overview.vue')
  },
  {
    path: '/platform',
    name: 'platform',
    component: () => import(/* webpackChunkName: "platform" */ '../views/platform.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contact.vue')
  },
  {
    path: '/about-ats/:page',
    name: 'aboutATS',
    props: true,
    component: () => import(/* webpackChunkName: "contact" */ '../views/products.vue')
  },

  // 404
  {
    path: '/unAuthorized',
    component: () => import(/* webpackChunkName: "403" */ '../components/unAuth.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '../components/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (
    to.name !== 'login' &&
    to.name !== 'verify' &&
    to.name !== 'resetPassword' &&
    to.name !== 'approve' &&
    to.name !== 'register' &&
    to.name !== 'Home' &&
    to.name !== 'aboutATS' &&
    to.name !== 'contact' &&
    to.name !== 'about' &&
    to.name !== 'platform' &&
    !localStorage.getItem('token')
  ) {
    next({ name: 'login' })
  } else {
    next()
  }
})
export default router
