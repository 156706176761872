<template>
    <v-container>
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
        <v-row>
            {{ text }}
            <v-spacer></v-spacer>
            <v-btn
            dark
            icon
            @click="snackbar = false"
            >
            <v-icon color="white">mdi-close</v-icon>
            </v-btn>
        </v-row>
        </v-snackbar>
        <div class="d-flex flex-wrap justify-space-between">
            <h1 class="blueDark--text mb-5">WB contracts</h1>
        </div>
        <div v-if="!loadingContracts && contracts.length">
            <contracts-table :contracts="contracts"></contracts-table>
        </div>
        <div v-else-if="!loadingContracts && !contracts.length">
            <p class="headline my-10 text-center"><v-icon>mdi-alert</v-icon> No contracts found!</p>
        </div>
    </v-container>
</template>

<script>
import { wbContract, headers } from '../../links'
import contractsTable from '@/components/contracts/contractsTable'

export default {
  components: {
    contractsTable
  },
  data () {
    return {
      contracts: [],
      loadingContracts: true,
      snackbar: false,
      color: '',
      text: ''
    }
  },
  methods: {
    // showRestoredMessage () {
    //   this.showMessage('success', 'Item Restored Successfully')
    //   this.getDeletedContract()
    // },
    // showError (msg) {
    //   this.showMessage('error', msg)
    // },
    // showMessage (color, text) {
    //   this.snackbar = true
    //   this.color = color
    //   this.text = text
    // },
    getWBContracts () {
      this.$store.dispatch('setLoadingState', true)
      this.$http.get(wbContract, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        this.contracts = response.body.data
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loadingContracts = false
        this.$store.dispatch('setLoadingState', false)
      })
    }
  },
  created () {
    this.getWBContracts()
  }
}
</script>
