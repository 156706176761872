<template>
    <v-container>
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
            <v-row>
                {{ text }}
                <v-spacer></v-spacer>
                <v-btn
                dark
                icon
                @click="snackbar = false"
                >
                <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-row>
        </v-snackbar>
        <h3 class="text-h6 blueDark--text mb-5">Contract History</h3>
        <v-row class="my-5">
            <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    label="End Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    color="blueDark"
                    outlined
                    v-on="on"
                    :loading="loadingContract"
                    hide-details
                    class="mr-15"
                    clearable
                ></v-text-field>
                </template>
                <v-date-picker
                color="blueDark"
                v-model="date"
                @input="dateMenu = false"
                ></v-date-picker>
            </v-menu>
            <v-btn elevation="0" height="56" x-large dark color="blueDark" @click="searchContracts">Search</v-btn>
        </v-row>
        <div v-if="contracts.length">
            <div v-for="(contract, i) in contracts" :key="i">
                <div class="contract-embed my-10" v-html="contract.details"></div>
                <span class="subheader">Edited By: {{contract.edit_by}} on {{contract.update_date.substr(0, 10)}} {{contract.update_date.split('T')[1].split(':')[0]+':'+contract.update_date.split('T')[1].split(':')[1]}}</span>
                <v-divider class="mt-5" v-if="i < contracts.length - 1"></v-divider>
            </div>
        </div>
        <p v-else class="caption text-center my-10"><v-icon left>mdi-alert</v-icon>No contracts; Change date value or search without date to get all previous history</p>
    </v-container>
</template>

<script>
import { contractHistory, headers } from '../../links'

export default {
  data () {
    return {
      contracts: [],
      loadingContract: false,
      dateMenu: false,
      snackbar: false,
      date: '',
      color: '',
      text: ''
    }
  },
  methods: {
    searchContracts () {
      this.dateMenu = false
      this.loadingContract = true
      if (!this.date) this.date = ''
      this.$http.get(contractHistory(this.$route.params.id, this.date), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        this.contracts = response.body.data
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loadingContract = false
      })
    }
  }
}
</script>

<style>
    .contract-embed {
        overflow: auto;
    }
    .contract-embed table , .contract-embed table td {
        border: 1px solid grey !important;
    }
</style>
