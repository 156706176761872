import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: '2.1.0',
    currency: {
      code: 'USD',
      countryName: 'UNITED STATES',
      name: 'DOLLAR',
      rate: 1
    },
    currencies: null,
    airports: {
      departureAirports: [],
      arrivalAirports: []
    },
    flightDates: {
      departureDates: [],
      arrivalDate: null
    },
    passengers: {
      adults: 1,
      children: 0,
      infants: 0,
      classType: { text: 'Economy', value: 'Y' }
    },
    preferredAirline: null,
    flightResults: {},
    flightPromotions: [],
    trip: null,
    flightType: 'round',
    flightsTimeOut: false,
    flightsTimeOutFunction: null,
    flightsSearchTimeOutFunction: null,
    writtenAddress: '',
    writtenCity: '',
    writtenState: '',
    writtenCountry: 'US',
    zipCode: '',
    isDirectFlights: false,
    hotelTimeOut: false,
    hotelTimeOutFunction: null,
    hotelSearchTimeOutFunction: null,
    hotelSearch: {
      checkInDate: null,
      checkOutDate: null,
      destination: null,
      guestsInfo: [
        {
          adults: 1,
          children: 0,
          childrenAges: []
        }
      ]
    },
    user: null,
    countryCode: 'US',
    guestData: null,
    starRating: '',
    hotels: null,
    carInfo: {
      pickupLocation: null,
      dropLocation: null,
      pickup: new Date().toISOString().substring(0, 10),
      drop: new Date().toISOString().substring(0, 10),
      pickupTime: '00:00',
      dropTime: '00:30'
    },
    cars: null,
    carsTimeOut: false,
    carsTimeOutFunction: null,
    carsSearchTimeOutFunction: null,
    homeTrips: [],
    newMail: false,
    userMails: [],
    travellersData: [],
    showLoading: false
  },
  mutations: {
    setVersion (state, payload) {
      state.version = payload
    },
    setCurrency (state, payload) {
      state.currency = payload
    },
    setCurrencies (state, payload) {
      state.currencies = payload
    },
    setAirports (state, payload) {
      switch (payload.direction) {
        case 'departure':
          state.airports.departureAirports[payload.index] = payload.data
          break
        // case 'departure2':
        //   state.airports.departureAirports[1] = payload.data
        //   break
        // case 'departure3':
        //   state.airports.departureAirports[2] = payload.data
        //   break
        case 'arrival':
          state.airports.arrivalAirports[payload.index] = payload.data
          break
        // case 'arrival2':
        //   state.airports.arrivalAirports[1] = payload.data
        //   break
        // case 'arrival3':
        //   state.airports.arrivalAirports[2] = payload.data
        //   break
        case 'carPickup':
          state.carInfo.pickupLocation = payload.data
          break
        case 'carDrop':
          state.carInfo.dropLocation = payload.data
          break
        default:
          break
      }
    },
    setDates (state, payload) {
      switch (payload.type) {
        case 'departure':
          state.flightDates.departureDates[0] = payload.data
          break
        case 'multi':
          console.log(payload)
          state.flightDates.departureDates[payload.index] = payload.data
          break
        // case 'departure3':
        //   state.flightDates.departureDates[2] = payload.data
        //   break
        case 'arrival':
          state.flightDates.arrivalDate = payload.data
          break
        case 'pickup':
          state.carInfo.pickup = payload.data
          break
        case 'drop':
          state.carInfo.drop = payload.data
          break
        default:
          break
      }
    },
    setPreferredAirline (state, payload) {
      state.preferredAirline = payload
    },
    setPassengersAdults (state, payload) {
      state.passengers.adults = payload
    },
    setPassengersChildren (state, payload) {
      state.passengers.children = payload
    },
    setPassengersInfants (state, payload) {
      state.passengers.infants = payload
    },
    setPassengersClass (state, payload) {
      state.passengers.classType = payload
    },
    setFlightResults (state, payload) {
      state.flightResults = payload
    },
    setFlightsMinPrice (state, payload) {
      state.flightResults.minPrice = payload
    },
    setFlightsMaxPrice (state, payload) {
      state.flightResults.maxPrice = payload
    },
    setFlightsMinStopsDuration (state, payload) {
      state.flightResults.minStopsDuration = payload
    },
    setFlightsMaxStopsDuration (state, payload) {
      state.flightResults.maxStopsDuration = payload
    },
    setAltAirlines (state, payload) {
      if (state.flightResults.airlines.length > 0) {
        payload.forEach(item => {
          state.flightResults.airlines.push(item)
        })
      } else {
        payload.forEach(item => {
          state.flightResults.airlines.push(item)
        })
      }
    },
    addSegment (state) {
      state.airports.departureAirports.push(null)
      state.airports.arrivalAirports.push(null)
      state.flightDates.departureDates.push(null)
    },
    updateFlights (state, payload) {
      state.flightResults.flights = payload
    },
    setFlightPromotions (state, payload) {
      state.flightPromotions = payload
    },
    setFlightsTimeOut (state, payload) {
      state.flightsTimeOut = payload
    },
    setFlightsTimeOutFunction (state, payload) {
      state.flightsTimeOutFunction = payload
    },
    setFlightsSearchTimeoutFunction (state, payload) {
      state.flightsSearchTimeOutFunction = payload
    },
    setType (state, payload) {
      state.flightType = payload
    },
    setDirectFlights (state, payload) {
      state.isDirectFlights = payload
    },
    setTravellersData (state, payload) {
      state.travellersData = payload
    },
    setWrittenAddress (state, payload) {
      state.writtenAddress = payload
    },
    setWrittenCity (state, payload) {
      state.writtenCity = payload
    },
    setWrittenState (state, payload) {
      state.writtenState = payload
    },
    setWrittenCountry (state, payload) {
      state.writtenCountry = payload
    },
    setZipCode (state, payload) {
      state.zipCode = payload
    },
    setHotelTimeOut (state, payload) {
      state.hotelTimeOut = payload
    },
    setHotelTimeOutFunction (state, payload) {
      state.hotelTimeOutFunction = payload
    },
    setHotelSearchTimeoutFunction (state, payload) {
      state.hotelSearchTimeOutFunction = payload
    },
    setHotelDestination (state, payload) {
      state.hotelSearch.destination = payload
    },
    setHotelCheckInDate (state, payload) {
      state.hotelSearch.checkInDate = payload
    },
    setHotelCheckOutDate (state, payload) {
      state.hotelSearch.checkOutDate = payload
    },
    setHotelGuestsInfo (state, payload) {
      state.hotelSearch.guestsInfo = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    setCountryCode (state, payload) {
      state.countryCode = payload
    },
    removeCountryCode (state) {
      state.countryCode = null
    },
    setGuestData (state, payload) {
      state.guestData = payload
    },
    removeGuestData (state) {
      state.guestData = null
    },
    setHotels (state, payload) {
      state.hotels = payload
    },
    setCarTimes  (state, payload) {
      payload.type === 'Pickup Time' ? state.carInfo.pickupTime = payload.data : state.carInfo.dropTime = payload.data
    },
    setCars (state, payload) {
      state.cars = payload
    },
    setCarsTimeOut (state, payload) {
      state.carsTimeOut = payload
    },
    setCarsTimeOutFunction (state, payload) {
      state.carsTimeOutFunction = payload
    },
    setCarsSearchTimeOutFunction (state, payload) {
      state.carsSearchTimeOutFunction = payload
    },
    setHomeTrips (state, payload) {
      state.homeTrips = payload
    },
    setUserMails (state, payload) {
      state.userMails = payload
    },
    setTrip (state, payload) {
      state.trip = payload
    },
    setStarRating (state, payload) {
      state.starRating = payload
    },
    removeAirportsData (state) {
      state.airports.departureAirports = []
      state.airports.arrivalAirports = []
    },
    removeDatesData (state) {
      state.flightDates.departureDates = []
      state.flightDates.arrivalDate = null
    },
    removePassengersData (state) {
      state.passengers = {
        adults: 1,
        children: 0,
        infants: 0,
        classType: { text: 'Economy', value: 'Y' }
      }
    },
    removeSegment (state) {
      console.log(state.airports)
      state.airports.departureAirports.pop()
      state.airports.arrivalAirports.pop()
      state.flightDates.departureDates.pop()
      console.log(state.airports)
    },
    removeFlightResults (state) {
      state.flightResults = {}
    },
    removeHotelsResults (state) {
      state.hotels = null
    },
    removeCarsResults (state) {
      state.cars = null
    },
    removeUser (state) {
      state.user = null
    },
    clearHotelGuests (state) {
      state.guestsInfo = [
        {
          adults: 1,
          children: 0,
          childrenAges: []
        }
      ]
    },
    clearHotelTimeoutFunction (state) {
      clearTimeout(state.hotelTimeOutFunction)
    },
    clearFlightsTimeoutFunction (state) {
      clearTimeout(state.flightsTimeOutFunction)
    },
    clearHotelSearchTimeoutFunction (state) {
      clearTimeout(state.hotelSearchTimeOutFunction)
    },
    clearFlightsSearchTimeoutFunction (state) {
      clearTimeout(state.flightsSearchTimeOutFunction)
    },
    clearDropLocation (state) {
      state.carInfo.dropLocation = null
    },
    clearCarsTimeOutFunction (state) {
      clearTimeout(state.carsTimeOutFunction)
    },
    clearCarsSearchTimeOutFunction (state) {
      clearTimeout(state.carsSearchTimeOutFunction)
    },
    setLoadingState (state, payload) {
      state.showLoading = payload
    }
  },
  actions: {
    setVersion (context, payload) {
      context.commit('setVersion', payload)
    },
    setCurrency ({ commit }, payload) {
      commit('setCurrency', payload)
    },
    setCurrencies ({ commit }, payload) {
      commit('setCurrencies', payload)
    },
    setAirports (context, payload) {
      context.commit('setAirports', payload)
    },
    setDates (context, payload) {
      // payload.data = new Date(payload.data).toLocaleString().substring(0, 10)
      context.commit('setDates', payload)
    },
    addSegment (context) {
      context.commit('addSegment')
    },
    setPreferredAirline (context, payload) {
      context.commit('setPreferredAirline', payload)
    },
    setPassengersAdults (context, payload) {
      context.commit('setPassengersAdults', payload)
    },
    setPassengersChildren (context, payload) {
      context.commit('setPassengersChildren', payload)
    },
    setPassengersInfants (context, payload) {
      context.commit('setPassengersInfants', payload)
    },
    setPassengersClass (context, payload) {
      context.commit('setPassengersClass', payload)
    },
    setFlightResults (context, payload) {
      context.commit('setFlightResults', payload)
    },
    setFlightsMinPrice (context, payload) {
      context.commit('setFlightsMinPrice', payload)
    },
    setFlightsMaxPrice (context, payload) {
      context.commit('setFlightsMaxPrice', payload)
    },
    setFlightsMinStopsDuration (context, payload) {
      context.commit('setFlightsMinStopsDuration', payload)
    },
    setFlightsMaxStopsDuration (context, payload) {
      context.commit('setFlightsMaxStopsDuration', payload)
    },
    setAltAirlines (context, payload) {
      context.commit('setAltAirlines', payload)
    },
    updateFlights (context, payload) {
      context.commit('updateFlights', payload)
    },
    setType (context, payload) {
      context.commit('setType', payload)
    },
    setTravellersData (context, payload) {
      context.commit('setTravellersData', payload)
    },
    setWrittenAddress (context, payload) {
      context.commit('setWrittenAddress', payload)
    },
    setWrittenCity (context, payload) {
      context.commit('setWrittenCity', payload)
    },
    setWrittenState (context, payload) {
      context.commit('setWrittenState', payload)
    },
    setWrittenCountry (context, payload) {
      context.commit('setWrittenCountry', payload)
    },
    setZipCode (context, payload) {
      context.commit('setZipCode', payload)
    },
    setHotelTimeOut (context, payload) {
      context.commit('setHotelTimeOut', payload)
    },
    setHotelTimeOutFunction (context, payload) {
      context.commit('setHotelTimeOutFunction', payload)
    },
    setHotelSearchTimeoutFunction (context, payload) {
      context.commit('setHotelSearchTimeoutFunction', payload)
    },
    setFlightsTimeOut (context, payload) {
      context.commit('setFlightsTimeOut', payload)
    },
    setFlightsTimeOutFunction (context, payload) {
      context.commit('setFlightsTimeOutFunction', payload)
    },
    setFlightsSearchTimeoutFunction (context, payload) {
      context.commit('setFlightsSearchTimeoutFunction', payload)
    },
    setFlightPromotions (context, payload) {
      context.commit('setFlightPromotions', payload)
    },
    setDirectFlights (context, payload) {
      context.commit('setDirectFlights', payload)
    },
    setUser (context, payload) {
      context.commit('setUser', payload)
    },
    setHotelDestination (context, payload) {
      context.commit('setHotelDestination', payload)
    },
    setHotelCheckInDate (context, payload) {
      context.commit('setHotelCheckInDate', payload)
    },
    setHotelCheckOutDate (context, payload) {
      context.commit('setHotelCheckOutDate', payload)
    },
    setHotelGuestsInfo (context, payload) {
      context.commit('setHotelGuestsInfo', payload)
    },
    setCountryCode (context, payload) {
      context.commit('setCountryCode', payload)
    },
    removeCountryCode (context) {
      context.commit('removeCountryCode')
    },
    setGuestData (context, payload) {
      context.commit('setGuestData', payload)
    },
    removeGuestData (context) {
      context.commit('removeGuestData')
    },
    setHotels (context, payload) {
      context.commit('setHotels', payload)
    },
    setCarTimes (context, payload) {
      context.commit('setCarTimes', payload)
    },
    setCars (context, payload) {
      context.commit('setCars', payload)
    },
    setCarsTimeOut (context, payload) {
      context.commit('setCarsTimeOut', payload)
    },
    setCarsTimeOutFunction (context, payload) {
      context.commit('setCarsTimeOutFunction', payload)
    },
    setCarsSearchTimeOutFunction (context, payload) {
      context.commit('setCarsSearchTimeOutFunction', payload)
    },
    setHomeTrips (context, payload) {
      context.commit('setHomeTrips', payload)
    },
    setUserMails (context, payload) {
      context.commit('setUserMails', payload)
    },
    setStarRating (context, payload) {
      context.commit('setStarRating', payload)
    },
    setTrip (context, payload) {
      context.commit('setTrip', payload)
    },
    removeAirportsData (context) {
      context.commit('removeAirportsData')
    },
    removeDatesData (context) {
      context.commit('removeDatesData')
    },
    removeSegment (context) {
      context.commit('removeSegment')
    },
    removePassengersData (context) {
      context.commit('removePassengersData')
    },
    removeFlightResults (context) {
      context.commit('removeFlightResults')
    },
    removeHotelsResults (context) {
      context.commit('removeHotelsResults')
    },
    removeCarsResults (context) {
      context.commit('removeCarsResults')
    },
    removeUser (context) {
      context.commit('removeUser')
    },
    clearHotelGuests (context) {
      context.commit('clearHotelGuests')
    },
    clearHotelTimeoutFunction (context) {
      context.commit('clearHotelTimeoutFunction')
    },
    clearFlightsTimeoutFunction (context) {
      context.commit('clearFlightsTimeoutFunction')
    },
    clearHotelSearchTimeoutFunction (context) {
      context.commit('clearHotelSearchTimeoutFunction')
    },
    clearFlightsSearchTimeoutFunction (context) {
      context.commit('clearFlightsSearchTimeoutFunction')
    },
    clearDropLocation (context) {
      context.commit('clearDropLocation')
    },
    clearCarsTimeOutFunction (context) {
      context.commit('clearCarsTimeOutFunction')
    },
    clearCarsSearchTimeOutFunction (context) {
      context.commit('clearCarsSearchTimeOutFunction')
    },
    setLoadingState ({ commit }, payload) {
      commit('setLoadingState', payload)
    }
  },
  plugins: [createPersistedState()]
})
