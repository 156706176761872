<template>
  <div>
    <v-footer
    padless
    elevation="3"
    >
      <v-card
        tile
        width="100%"
        color="#1F2746"
        v-if="$route.name !== 'approve'"
      >
        <v-card-text>
          <v-container fluid>
            <v-snackbar v-model="snackbar" top right :timeout="5000" :color="color">
              {{text}}
              <v-btn icon @click="snackbar = false"><v-icon color="white">mdi-close</v-icon></v-btn>
          </v-snackbar>
            <v-row justify="space-between">
              <v-col cols="12" sm="6" md="3">
                <span class="display-1 font-weight-bold white--text">ATS Fares</span>
                <p class="body-2 mt-5 grey--text">
                  ATS is a leading air fare consolidator, deliver the best fares, products, service, and opportunities to travel suppliers, partners, and vendors looking to improve their services and expand their business Working with more than 50+ carriers and being in business since 84' makes us truly your global partner.
                </p>
              </v-col>
              <v-col cols="6" sm="6" md="3">
                <span class="white--text font-weight-bold" style="font-size: 20px;">Accredited by</span>
                <hr class="divider">
                <v-img src="../static/arc.png" max-width="100" class="my-5"></v-img>
                <div style="margin-top: 50px;">
                  <v-img src="../static/iata.png" max-width="100" class="my-5"></v-img>
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="3">
                <span class="white--text font-weight-bold" style="font-size: 20px;">Supported GDS's</span>
                <hr class="divider">
                <v-img src="../static/logos.png" max-width="150" class="my-5"></v-img>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <span class="white--text font-weight-bold" style="font-size: 20px;">Contacts</span>
                <hr class="divider">
                <v-form
                class="mt-3"
                ref="contactForm"
                v-model="valid"
                >
                    <v-text-field dense :rules="nameRules" v-model="name" solo label="Your Name" required></v-text-field>

                    <v-text-field dense v-model="email" solo :rules="emailRules" label="E-mail" required></v-text-field>

                    <v-textarea dense height="70" v-model="message" solo label="Message"></v-textarea>

                    <v-hover v-slot="{ hover }">
                        <v-btn :disabled="!valid" :loading="sendLoad" elevation="5" :color="hover ? 'blueDark' : 'orange'" class="white--text px-15 font-weight-bold d-block btn-trans" tile @click="submit">Send</v-btn>
                    </v-hover>
                </v-form>
                <!-- <div class="my-5">
                  <v-icon color="white">mdi-map-marker</v-icon>
                  <span class="ml-3 white--text caption">131 West 33rd St. unit 10D, New York NY-10001</span>
                </div>
                <div class="my-5">
                  <v-icon color="white">mdi-phone</v-icon>
                  <span class="ml-3 white--text caption">1- 833-287-3273</span>
                </div>
                <div class="my-5">
                  <v-icon color="white">mdi-email-open</v-icon>
                  <span class="ml-3 white--text caption">support@atsfares.com</span>
                </div> -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <hr style="background-color: grey; border: none; height: 0.5px; margin: 20px">
        <p class="bod-2 grey--text text-right mr-5">
          &copy; {{ new Date().getFullYear() }} All rights reserved. <router-link to="/terms&conditions" class="grey--text"> Terms & Conditions</router-link> - <router-link class="grey--text" to="/privacy-policy"> Privacy Policy</router-link>
        </p>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import { contact, headersNoAuth } from '../links'

export default {
  data () {
    return {
      dialog: false,
      dialog1: false,
      title: '',
      social: [
        { icon: 'mdi-facebook', link: 'https://www.facebook.com/Fare33TravelServices', color: '#1877f2' },
        { icon: 'mdi-twitter', link: 'https://twitter.com/fare33_info', color: '#1da1f2' },
        { icon: 'mdi-instagram', link: 'https://www.instagram.com/fare33.info/', color: '#c32aa3' }
      ],
      valid: true,
      name: '',
      nameRules: [
        v => (!!v && v.length > 1) || 'Item is required at least 2 characters',
        v => /^[A-Za-z\s]*$/.test(v) || 'Name Must be letters only'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      message: '',
      sendLoad: false,
      snackbar: false,
      color: '',
      text: ''
    }
  },
  methods: {
    goTo (page) {
      this.$router.push({ name: page })
    },
    submit () {
      this.$refs.contactForm.validate()
      if (this.valid) {
        this.sendLoad = true
        const body = {
          name: this.name,
          email: this.email,
          message: this.message
        }
        this.$http.post(contact, body, { headers: headersNoAuth() }).then(response => {
          this.snackbar = true
          this.color = 'success'
          this.text = 'Your message was sent successfully, we will contact you soon.'
          this.$refs.contactForm.reset()
        }).catch(() => {
          this.snackbar = true
          this.color = 'error'
          this.text = 'Oops, Your message was not sent, try again later or contact us through other meduim.'
        }).finally(() => {
          this.sendLoad = false
        })
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
      }
    }
  }
}
</script>

<style scoped>
  .footer {
    background-size: contain;
    background-position: center;
  }
  .download-link {
    text-decoration: none;
    font-weight: 700;
    font-size: 1rem;
  }
  .details {
    font-size: 11px;
    margin-right: 15px;
  }
  h3 {
    font-size: 15px;
  }
  ul {
    list-style: none;
  }
  .social-links {
    text-decoration: none;
  }
  .list-items {
    text-decoration: none;
    color: grey;
    font-size: 12px;
    transition: .2s linear;
  }
  .list-items:hover {
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  .subscripe {
    min-height: 220px;
    position: relative;
  }
  .btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .divider {
    background-color:#fff;
    width: 50px;
    height: 3px;
    margin-top: 5px;
    border: none;
  }
  .privacy-link {
    color: grey;
    text-decoration: none;
  }
  .privacy-link:hover {
    cursor: pointer;
    color: white;
  }
  .adam-footer {
    border-top: 3px solid #FDB71A !important;
  }
  .social-adam-cont {
    display: flex;
    margin-top: 5px;
  }
  .social-adam {
    background-color: #FDB71A;
    height: 30px;
    width: 30px;
    margin-right: 5px;
    text-align: center;
  }
  .social-adam:hover {
    background-color: #f1ad1a;
  }
  .social-adam span {
    color: #1a3057;
    font-size: 1.3rem;
    font-weight: 900;
  }
  .social-adam-cont a {
    text-decoration: none;
  }
  @media screen and (max-width: 960px) {
    .brand {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .details {
      margin-top: 50px;
      text-align: center;
    }
    ul {
      text-align: center;
      margin: 20px;
    }
  }
  @media screen and (max-width: 526px) {
    ul {
     list-style: disc;
    }
  }
  .links-list {
    list-style: none;
    margin-top: 20px;
    padding-left: 0px;
  }
  .links-list li {
    margin: 10px 0;
    text-align: left;
  }
  .links-list li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
  }
</style>
