<template>
  <div>
    <div class="navinfo d-none d-md-block" v-if="$route.name !== 'approve'">
      <v-container class="py-5">
        <v-row>
          <div class="ml-1">
            <v-icon small color="white">mdi-map-marker</v-icon>
            <span class="ml-3 white--text caption">131 West 33rd St. unit 10D, New York NY-10001</span>
          </div>
          <div class="ml-5">
            <v-icon small color="white">mdi-phone</v-icon>
            <a href="tel:+1 (617) 367-7155"><span class="ml-3 white--text caption"> +1 (617) 367-7155</span></a>
          </div>
          <div class="ml-5">
            <v-icon small color="white">mdi-email-open</v-icon>
            <a href="mailto:support@atsfares.com"><span class="ml-3 white--text caption">support@atsfares.com</span></a>
          </div>
        </v-row>
      </v-container>
    </div>
    <v-app-bar
    dark
    color="white"
    elevation="1"
    style="height: 80px;"
    scroll-off-screen
    >
      <v-container fluid>
        <v-row align="center">
            <v-btn icon @click="drawer = !drawer"
            class="d-flex d-md-none mt-3"
            >
              <v-icon large color="secondary">mdi-menu</v-icon>
            </v-btn>
            <v-col cols="2" class="pb-4">

              <router-link style="text-decoration: none;" to="/">
                <v-img src="../static/logo-dark.png" class="mt-0" alt="fare33 logo" min-width="90" max-width="136"></v-img>
              </router-link>

            </v-col>

            <div class="d-flex mb-3" v-if="$route.name && user">
              <template v-if="(user.role !== 'subAgent' && user.role !== 'ats_subAgent') || user.permissions.includes('can-make-bookings')">
                <v-btn
                v-for="tab in tabs"
                :key="tab.title"
                exact
                :to="{ name: tab.route}"
                :text="$route.name !== tab.title"
                :elevation="$route.name == tab.title ? 2 : 0"
                :tile="$route.name == tab.title" :color="$route.name == tab.title ? 'white' : 'secondary'"
                :height="70"
                :large="$vuetify.breakpoint.lgAndUp"
                class="d-none d-md-flex"
                >
                    <v-icon color="secondary">{{tab.icon}}</v-icon>
                    <span class="body-1 ml-2 font-weight-medium blueDark--text">{{tab.title}}</span>
                </v-btn>
              </template>
              <v-menu
              v-if="(user.role !== 'subAgent' && user.role !== 'ats_subAgent') || user.permissions.includes('can-see-contracts') || !user"
              bottom
              left
              nudge-bottom="50"
              nudge-right="50"
              open-on-hover
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  v-bind="attrs"
                  v-on="on"
                  exact
                  :text="!$route.path.includes('contract') || !$route.path.includes('contracts')"
                  :elevation="$route.path.includes('contract') || $route.path.includes('contracts') ? 1 : 0"
                  :tile="$route.path.includes('contract') || $route.path.includes('contracts')"
                  color="white"
                  :height="70"
                  :large="$vuetify.breakpoint.lgAndUp"
                  class="d-none d-md-flex"
                  >
                      <v-icon color="secondary">mdi-file-document-multiple</v-icon>
                      <span class="body-1 ml-2 font-weight-medium blueDark--text">Contracts</span>
                      <v-icon right color="secondary">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                  color="orange"
                  link
                  exact
                  :to="{name: item.route}"
                  v-for="(item, i) in contractLinks"
                  :key="i"
                  >
                    <v-list-item-icon><v-icon>{{item.icon}}</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                  color="orange"
                  @click="goTo('https://www2.arccorp.com/support-training/travel-agencies/participating-airline-information/?utm_source=MyARC_Agency&utm_medium=text&utm_campaign=2020-03_Ow-Br-Edu_Refunds')"
                  >
                    <v-list-item-icon><v-icon>mdi-file-document</v-icon></v-list-item-icon>
                    <v-list-item-title>ARC</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- group pnr -->
              <template v-if="$cookies.isKey('userToken') ">
                <v-btn
                v-if="user.role === 'ats_admin' || user.permissions.includes('can-see-group-pnr')"
                exact
                to="/group-pnr"
                :text="$route.name !== 'groupPnr'"
                :elevation="$route.name == 'groupPnr' ? 2 : 0"
                :tile="$route.name == 'groupPnr'"
                :color="$route.name == 'groupPnr' ? 'white' : 'secondary'"
                :height="70"
                :large="$vuetify.breakpoint.lgAndUp"
                class="d-none d-md-flex"
                >
                  <v-icon color="secondary">mdi-ticket</v-icon>
                  <span class="body-1 ml-2 font-weight-medium blueDark--text">Group PNR</span>
                </v-btn>
              </template>
            </div>

            <div class="d-flex" v-else>
              <v-btn
              exact
              :to="{ name: 'login'}"
              text=""
              :height="70"
              :large="$vuetify.breakpoint.lgAndUp"
              class="d-none d-md-flex"
              >
                <span class="body-1 ml-2 font-weight-medium blueDark--text">Airfares</span>
              </v-btn>

              <v-btn
              exact
              :to="{ name: 'login'}"
              text=""
              :height="70"
              :large="$vuetify.breakpoint.lgAndUp"
              class="d-none d-md-flex"
              >
                <span class="body-1 ml-2 font-weight-medium blueDark--text">Commissions</span>
              </v-btn>
            </div>

            <v-menu offset-y open-on-hove>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="py-2 d-none d-md-flex mb-3"
                color="secondary"
                dark
                v-bind="attrs"
                x-large
                text
                v-on="on"
                >
                  about us <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link to="/about">
                  <v-list-item-title>Overview</v-list-item-title>
                </v-list-item>
                <v-list-item link to="/platform">
                  <v-list-item-title>Platform</v-list-item-title>
                </v-list-item>
                <v-list-item link to="/contact">
                  <v-list-item-title>Contact Us</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <!-- <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="d-none d-md-block"
                color="secondary"
                dark
                v-bind="attrs"
                outlined
                small
                v-on="on"
                >
                  <v-icon left>mdi-cash</v-icon> {{currency.code}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                v-for="(item, i) in currencies"
                :key="i"
                @click="$store.dispatch('setCurrency', item)"
                >
                  <v-list-item-title>{{ item.code }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->

            <router-link class="text-decoration-none d-none d-sm-flex" :to="{ name: 'login'} " v-if="!$cookies.isKey('userToken')">
              <v-btn
              elevation="0"
              :large="$vuetify.breakpoint.lgAndUp"
              :small="$vuetify.breakpoint.mdAndDown"
              color="secondary"
              height="47"
              class="px-10 ml-1"
              >
                <span>Login</span>
                <v-icon right>mdi-login-variant</v-icon>
              </v-btn>
            </router-link>

            <v-menu offset-y v-else class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-btn
                color="white --text"
                text
                small
                v-on="on"
                >
                  <v-avatar color="secondary" class="mr-2" size="30">
                    <span class="white--text body-1">
                      {{user.name.charAt(0).toUpperCase()}}
                    </span>
                  </v-avatar>
                  <!-- <span class="d-none d-sm-flex secondary--text">{{user.name.substr(0, user.name.indexOf(' '))}}</span> -->
                </v-btn>
              </template>
              <v-list>
                <v-list-item link exact :to="{ name: 'profile' }">
                  <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-row>
      </v-container>

    </v-app-bar>

    <v-navigation-drawer
    v-model="drawer"
    absolute
    color="white"
    dark
    temporary
    >
      <v-list
      nav
      dense
      >
        <v-list-item-group
        v-model="group"
        active-class="secondary--text text-accent-4"
        >
          <v-list-item link exact to="/">
            <v-img src="../static/logo-dark.png" alt="ATS logo" max-width="50px"></v-img>
          </v-list-item>

          <v-list-item two-line link exact :to="{ name: 'login' }" v-if="!$cookies.isKey('userToken')">
            <v-list-item-avatar>
              <v-icon color="secondary">mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">Login / Register</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line link exact :to="{ name: 'profile' }" v-else>
            <v-list-item-avatar v-if="user !== null">
              <v-avatar color="secondary" class="text-center" size="32">

                  <span class="white--text body-1">
                    {{user.name.charAt(0).toUpperCase()}}
                  </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>

              <v-list-item-title v-cloak class="secondary--text">{{user.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <template v-if="user">
            <template v-if="(user.role !== 'subAgent' && user.role !== 'ats_subAgent') || user.permissions.includes('can-make-bookings') || !user">
              <v-list-item active-class="'active-route-blue" link exact :to="{ name: item.route }" v-for="item in tabs" :key="item.title">
                  <v-list-item-title :class="$route.name === item.title ? 'white--text' : 'secondary--text'">
                    <v-icon small :color="$route.name === item.title ? 'white' : 'secondary'">{{item.icon}}</v-icon>
                    {{item.title}}
                  </v-list-item-title>
              </v-list-item>
            </template>
            <template v-if="(user.role !== 'subAgent' && user.role !== 'ats_subAgent') || user.permissions.includes('can-see-contracts') || !user">
              <v-list-item
              active-class="'active-route-blue"
              class="blueDark--text"
              link
              exact
              :to="{name: item.route}"
              v-for="(item, i) in contractLinks"
              :key="i"
              >
                <v-list-item-icon><v-icon color="blueDark">{{item.icon}}</v-icon></v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>
          </template>

          <v-list-item
          v-if="(user.role !== 'subAgent' && user.role !== 'ats_subAgent') || user.permissions.includes('can-see-contracts') || !user"
          color="orange"
          @click="goTo('https://www2.arccorp.com/support-training/travel-agencies/participating-airline-information/?utm_source=MyARC_Agency&utm_medium=text&utm_campaign=2020-03_Ow-Br-Edu_Refunds')"
          >
            <v-list-item-icon><v-icon>mdi-file-document</v-icon></v-list-item-icon>
            <v-list-item-title>ARC</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item v-if="user.permissions.includes('can-see-contracts')">
            <v-btn @click="goTo('commissions')" text x-small color="secondary">Commissions</v-btn>
          </v-list-item> -->

          <template v-else>
            <v-list-item
            active-class="'active-route-blue"
            class="blueDark--text"
            link
            exact
            :to="{name: 'login'}"
            >
              <v-list-item-title>Airfares</v-list-item-title>
            </v-list-item>

            <v-list-item
            active-class="'active-route-blue"
            class="blueDark--text"
            link
            exact
            :to="{name: 'login'}"
            >
              <v-list-item-title>Commissions</v-list-item-title>
            </v-list-item>
          </template>

          <template v-if="$cookies.isKey('userToken') ">
            <v-list-item
            v-if="user.role === 'ats_admin' || user.permissions.includes('can-see-group-pnr')"
            active-class="'active-route-blue"
            class="blueDark--text"
            link
            exact
            to="/group-pnr"
            >
              <v-list-item-title>Group PNR</v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item link to="/about" class="blueDark--text">
            <v-list-item-title>Overview</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/platform" class="blueDark--text">
            <v-list-item-title>Platform</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/contact" class="blueDark--text">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="py-2"
                color="secondary"
                dark
                v-bind="attrs"
                outlined
                large
                v-on="on"
                >
                  <v-icon left>mdi-cash</v-icon> {{currency.code}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                v-for="(item, i) in currencies"
                :key="i"
                @click="$store.dispatch('setCurrency', item)"
                >
                  <v-list-item-title>{{ item.code }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { logout, headers } from '../links'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      drawer: false,
      group: null,
      tabs: [
        { title: 'flights', route: 'flights', icon: 'mdi-airplane' }
        // { title: 'hotels', route: 'hotels', icon: 'mdi-domain' },
        // { title: 'cars', route: 'cars', icon: 'mdi-car' }
      ],
      contractLinks: [
        { title: 'Airlines Contracts', route: 'contracts', icon: 'mdi-file-document-multiple' },
        { title: 'WB', route: 'wbContracts', icon: 'mdi-file-marker' },
        { title: 'Waivers', route: 'waivers', icon: 'mdi-file-document-multiple' },
        { title: 'COMMISION CAP', route: 'caps', icon: 'mdi-file-arrow-left-right' }
      ],
      user: null
    }
  },
  computed: {
    userStore () {
      return this.$store.state.user
    },
    ...mapState(['currency', 'currencies'])
  },
  watch: {
    userStore (newVal) {
      this.user = newVal
    },
    group () {
      this.drawer = false
    }
  },
  methods: {
    goTo (link) {
      window.open(link, '_blank')
    },
    logout () {
      this.$http.post(logout, null, { headers: headers(this.$cookies.get('userToken')) }).then(() => {
        this.$store.dispatch('removeUser')
        this.$cookies.remove('userToken')
        localStorage.removeItem('userId')
        localStorage.removeItem('token')
        this.$router.push({ name: 'login' })
      })
    }
  },
  created () {
    this.user = this.$store.state.user
    if (this.user) {
      if (this.user.role === 'ats_admin') this.contractLinks.push({ title: 'Add Contract', route: 'addContract', icon: 'mdi-file-document-plus' })
    }
  }
}
</script>

<style>
  [v-cloak] { display: none; }
  .subNav {
    overflow-x: auto;
  }
  /* .v-toolbar {
    height: 143px !important;
  } */
  .navinfo {
    background-color: #1C2340;
  }
  .v-toolbar__content {
    height: 95px !important;
  }
  .v-toolbar__extension {
    background-color: #325AAF;
  }
  .active-route {
    background-color: #E28134;
    border-radius: 5PX;
    height: 58px;
  }
  .active-route-blue {
    background-color: #150958;
    border-radius: 5PX;
    height: 58px;
  }
</style>
