import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#333',
        secondary: '#150958',
        greyText: '#707070',
        orange: '#FDB71A',
        blueDark: '#202847',
        blueLight: '#2BB5CB'
      }
    }
  }
})
