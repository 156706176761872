<template>
  <div>
    <v-progress-linear
    indeterminate
    color="secondary"
    height="10"
    top
    :active="loaded"
    ></v-progress-linear>
    <v-snackbar
    :color="color"
    :timeout="3000"
    top
    v-model="alert"
    >
      <v-row>
        {{text}}
        <v-spacer></v-spacer>
        <v-icon color="white" @click="alert = false">mdi-close</v-icon>
      </v-row>
    </v-snackbar>

    <!-- profile banner -->
    <v-parallax
    dark
    src="../static/bg1.jpg"
    gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
    v-if="!loaded"
    >
      <v-row
      align="center"
      class="pa-5"
      >
        <v-col
        class="text-left pa-5"
        cols="12"
        sm="6"
        md="4"
        style="background-color: #00000080; border-left: 5px solid white;"
        >
          <h1 class="text-h2 font-weight-bold mb-4">
            Hi, {{user.name}}
          </h1>
          <h4 class="text-h3 font-weight-light">
            Welcome to your profile
          </h4>
        </v-col>
      </v-row>
    </v-parallax>

    <v-row v-if="!loaded" justify="center" class="mt-5">
      <v-col cols="12" sm="4" class="pl-5">
        <v-card class="pa-5" outlined>
          <div class="d-block text-left">
            <p class="body-1 primary--text"><v-icon>mdi-account</v-icon> {{user.name}}</p>
          </div>
          <v-divider class="my-5"></v-divider>
          <div class="d-block text-left">
            <p class="body-1 primary--text"><v-icon>mdi-email</v-icon> {{user.email}}</p>
          </div>
          <v-divider class="my-5"></v-divider>
          <div class="d-block text-left">
            <p class="body-1 primary--text"><v-icon>mdi-phone</v-icon> {{user.phone}}</p>
          </div>
          <div class="d-flex justify-space-between flex-wrap mt-5">
            <v-btn @click="dialog = true" color="white" class="orange--text mb-2">
              <v-icon small left color="blue">
                mdi-circle-edit-outline
              </v-icon>
              Edit Profile
            </v-btn>

            <v-btn @click="passwordDialog = true" color="white" class="orange--text">
              <v-icon small left color="blue">
                mdi-lock
              </v-icon>
              update password
            </v-btn>
          </div>
        </v-card>

        <v-card v-if="user.subAgent" class="pa-5 my-5" outlined>
          <v-card-title class="px-0 blue--text">Agents Emails</v-card-title>
          <div v-if="user.subAgent.agents_emails.length">
            <div v-for="item in user.subAgent.agents_emails" :key="item">
              <div class="d-flex justify-space-between align-start">
                <p class="body-1 my-1 mt-2 primary--text"><v-icon>mdi-email</v-icon> {{item}}</p>
                <v-btn icon color="error" @click="manageEmails('remove', item)"><v-icon>mdi-trash-can</v-icon></v-btn>
              </div>
              <v-divider class="my-5"></v-divider>
            </div>
          </div>
          <p v-else class="body-1 primary--text"><v-icon>mdi-alert</v-icon> No emails found</p>
          <div class="text-center mt-5">
            <v-text-field v-model="emailToAdd" :rules="[v => (/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || v === '') || 'E-mail must be valid']" outlined label="Add Email" hint="Add email to emails list" persistent-hint append-icon="mdi-plus-circle" @click:append="manageEmails('add', '')"  @keyup.enter="manageEmails('add', '')"></v-text-field>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" sm="8" class="pr-5">
        <v-tabs
        v-model="tab"
        background-color="#eee"
        color="blue darken--2"
        dark
        grow
        v-if="!loaded"
        >
          <v-tab class="blue--text">
            Flights Booking
          </v-tab>
          <v-tab class="blue--text">
            Hotels Booking
          </v-tab>
          <v-tab class="blue--text">
            Cars Booking
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">

          <!-- flights -->
          <v-tab-item class="text-center">
            <p v-if="flightBookings.length === 0" class="display-1 font-weight-thin">No flights bookings found</p>
              <v-card outlined>
                <v-data-table
                v-if="flightBookings.length > 0"
                :items="flightBookings"
                :headers="headers"
                :items-per-page="10"
                class="mx-0 px-0"
                elevation="1"
                :loading="tableLoad"
                >
                  <template v-slot:item.booking_reference="{ item }">
                    <!-- <span v-if="user.role === 'customer'">{{item.booking_reference}}</span> -->
                    <v-btn text color="blue" @click="showMail(item.booking_reference)">{{item.booking_reference}}</v-btn>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <span>{{item.price | changeCurrency(item.price)}}</span>
                  </template>
                  <template v-slot:item.booking_date="{ item }">
                    <p v-if="item.booking_date" class="mb-0">{{ getThisDate(item.booking_date.split(' ')[0]) }}</p>
                    <span v-if="item.booking_date" class="caption grey--text darken-2">{{ item.booking_date.split(' ')[1] }}</span>
                  </template>
                  <template v-slot:item.origin="{ item }">
                    <span v-if="item.departureAirport">{{`(${item.departureAirport.code}) ${item.departureAirport.city},${item.departureAirport.country}`}}</span>
                    <span v-else>{{ item.flight_segments[0].departure_location }}</span>
                    <p v-if="item.departureDate" class="body-2 grey--text darken-1">{{getThisDate(item.departureDate)}}</p>
                    <p v-else class="body-2 grey--text darken-1">{{getThisDate(item.flight_segments[0].departure_date)}}</p>
                  </template>
                  <template v-slot:item.dist="{ item }">
                    <span v-if="item.arrivalAirport">{{`(${item.arrivalAirport.code}) ${item.arrivalAirport.city},${item.arrivalAirport.country}`}}</span>
                    <span v-else>{{ item.flight_segments[item.flight_segments.length - 1].arrival_location }}</span>
                    <p v-if="item.arrivalDate" class="body-2 grey--text darken-1">{{getThisDate(item.arrivalDate)}}</p>
                    <p v-else class="body-2 grey--text darken-1">{{getThisDate(item.flight_segments[item.flight_segments.length - 1].arrival_date)}}</p>
                  </template>
                  <template v-slot:item.cancel="{ item }">
                    <v-btn :disabled="item.status !== 'Initial' || item.status === 'Cancelled' || disableCancelBtn" text color="error" @click="cancelFlight(item)">Cancel</v-btn>
                  </template>
                  <template v-slot:item.ticketTimeLimit="{ item }">
                    <p v-if="item.ticketTimeLimit" class="mb-0">{{ getThisDate(item.ticketTimeLimit.split(' ')[0]) }}</p>
                    <span v-if="item.ticketTimeLimit" class="caption grey--text darken-2">{{ item.ticketTimeLimit.split(' ')[1] }}</span>
                  </template>
                  <template v-slot:item.ticket_number="{ item }">
                    <p>{{item.ticket_number ? item.ticket_number : 'Not ticketed yet'}}</p>
                  </template>
                </v-data-table>
              </v-card>
          </v-tab-item>

          <!-- hotels -->
          <v-tab-item class="pa-5 text-center">
            <p v-if="hotelsBookings.length === 0" class="display-1 font-weight-thin">No hotels bookings found</p>
            <v-data-table
            v-if="hotelsBookings.length > 0"
            :items="hotelsBookings"
            :items-per-page="10"
            :headers="hotelHeaders"
            class="elevation-1"
            >
              <template v-slot:item.price="{ item }">
                <span>{{item.price | changeCurrency(item.price)}}</span>
              </template>
              <template v-slot:item.date="{ item }">
                {{ getThisDate(item.booking_date.substr(0, 10)) }}, {{item.booking_date.substr(11, 16)}}
              </template>
              <template v-slot:item.desc="{ item }">
                {{item.guests.length}} guests, {{item.rooms.length}} rooms
              </template>
            </v-data-table>
          </v-tab-item>

          <!-- cars -->
          <v-tab-item class="pa-5 text-center">
            <p v-if="carBookings.length === 0" class="display-1 font-weight-thin">No Cars bookings found</p>
            <v-data-table
            v-if="carBookings.length > 0"
            :items="carBookings"
            :items-per-page="10"
            :headers="carsHeaders"
            class="elevation-1"
            >
              <template v-slot:item.price="{ item }">
                <span>{{item.price | changeCurrency(item.price)}}</span>
              </template>
              <template v-slot:item.booking_date="{ item }">
                {{ getThisDate(item.booking_date.substr(0, 10)) }}, {{item.booking_date.substr(11, 16)}}
              </template>
              <template v-slot:item.car="{ item }">
                {{ item.car_details[0].car_type }}
              </template>
              <template v-slot:item.vendor="{ item }">
                {{ item.car_details[0].vendor }}
              </template>
              <template v-slot:item.loc="{ item }">
                {{ item.car_details[0].pickup_location }}
              </template>
            </v-data-table>
          </v-tab-item>

          <!-- redeemed points -->
          <!-- <v-tab-item class="pa-5 text-center">
            <v-data-table
            v-if="redeem.length > 0"
            :items="redeem"
            :headers="redeemHeaders"
            :items-per-page="10"
            class="elevation-1"
            >
              <template v-slot:item.price="{ item }">
                <span>{{item.price | changeCurrency(item.price)}}</span>
              </template>
            </v-data-table>
          </v-tab-item> -->

          <!-- points collection -->
          <!-- <v-tab-item class="pa-5 text-center">
            <v-data-table
            v-if="points.length > 0"
            :items="points"
            :headers="pointsHeaders"
            :items-per-page="10"
            class="elevation-1"
            >
            </v-data-table> -->
          <!-- </v-tab-item> -->
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-container fluid>
      <v-dialog v-model="pnrDialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card v-if="!loaded">
          <v-toolbar height="60" dark color="secondary">
            <v-toolbar-content height="60" style="height: 80px;">
              <v-btn icon dark @click="pnrDialog = false">
                <v-icon class="mb-1">mdi-close</v-icon>
              </v-btn>
              <span class="text-h6">{{details.pnr_id}}</span>
            </v-toolbar-content>
            <v-spacer></v-spacer>
            <!-- <v-btn small class="mb-7" tile color="white red--text" @click="pnrDialog = false">
              request someone to issue
            </v-btn> -->
          </v-toolbar>
          <v-card-text>
            <pnr :details="details" @updateBookings="getBookings"></pnr>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- update profile -->
      <v-dialog
      v-model="dialog"
      width="500"
      >
        <v-card v-if="!loaded">
          <v-card-title
          class="headline secondary white--text"
          primary-title
          >
            Update your profile
            <v-spacer></v-spacer>
            <v-btn icon color="white" @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
            <v-form
            ref="form"
            v-model="valid"
            v-on:submit.prevent
            >
              <v-card-text>
                <v-text-field
                label="Full Name"
                v-model="name"
                outlined
                clearable
                prepend-inner-icon="mdi-account"
                :rules="[v => !!v || 'Name is required']"
                color="secondary"
                id="profile-fullName"
                >
                </v-text-field>
                <phoneInput @update="assignPhone" :phone="user.phone" />
              </v-card-text>

              <v-card-actions>
                <v-btn
                color="secondary"
                tile
                block
                type="submit"
                :disabled="!valid"
                :loading="loading"
                @click="update"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-form>
        </v-card>
      </v-dialog>

      <!-- update profile password -->
      <v-dialog v-model="passwordDialog" width="500">
        <v-card>
          <v-card-title class="headline blue white--text" primary-title>
            Update your password
            <v-spacer />
            <v-btn icon color="white" @click="passwordDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="mt-5">
            <v-text-field
              v-model="passwords.current_password"
              label="Old Password"
              type="password"
              prepend-inner-icon="mdi-lock"
              :rules="[v => !!v || 'Old Password is required', v => v.length >= 8 || 'Password must be at least 8 characters']"
              color="blue"
              outlined
              dense
            />

            <v-text-field
              v-model="passwords.new_password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="New Password"
              :type="showPassword ? 'text' : 'password'"
              prepend-inner-icon="mdi-lock-check"
              :rules="[v => !!v || 'New Password is required', v => v.length >= 8 || 'Password must be at least 8 characters']"
              color="blue"
              outlined
              dense
              @click:append="showPassword = !showPassword"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              tile
              color="blue white--text"
              :disabled="!(passwords.current_password && passwords.new_password)"
              :loading="changePasswordLoad"
              @click="changePassword"
            >
              Change
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { profile, mangeAgentEmails, cancelFlight, headers, userBookings, userUpdate, showPNR, changePassword } from '../links'
import pnr from '../components/showPnr.vue'
import phoneInput from '../components/mobile.vue'

export default {
  title () {
    return `-${this.$store.state.user.name || 'profile'}`
  },
  components: {
    pnr,
    phoneInput
  },
  data () {
    return {
      user: {},
      tab: null,
      flightBookings: [],
      hotelsBookings: [],
      points: [],
      redeem: [],
      carBookings: [],
      headers: [
        {
          text: 'PNR',
          align: 'start',
          sortable: false,
          value: 'booking_reference',
          width: 120
        },
        { text: 'Price', value: 'price' },
        { text: 'Status', value: 'status' },
        { text: 'Booked on', value: 'booking_date' },
        { text: 'From', value: 'origin' },
        { text: 'To', value: 'dist' },
        { text: 'ticketTimeLimit', value: 'ticketTimeLimit' },
        { text: 'Payment Type', value: 'payment_type' },
        { text: 'Cancel Flight', value: 'cancel' }
      ],
      hotelHeaders: [
        {
          text: 'Booking No.',
          align: 'start',
          sortable: false,
          value: 'booking_number'
        },
        { text: 'Hotel', value: 'hotel_name' },
        { text: 'Price', value: 'price' },
        { text: 'Payment Type', value: 'payment_type' },
        { text: 'Payment', value: 'payment_status' },
        { text: 'Status', value: 'booking_status' },
        { text: 'Date', value: 'date' },
        { text: 'Description', value: 'desc' }
      ],
      carsHeaders: [
        {
          text: 'Booking No.',
          align: 'start',
          sortable: false,
          value: 'booking_reference'
        },
        { text: 'Car', value: 'car' },
        { text: 'Vendor', value: 'vendor' },
        { text: 'Location', value: 'loc' },
        { text: 'Price', value: 'price' },
        { text: 'Payment Type', value: 'payment_type' },
        { text: 'Status', value: 'status' },
        { text: 'Date', value: 'booking_date' }
      ],
      redeemHeaders: [
        {
          text: 'Booking Reference.',
          align: 'start',
          sortable: false,
          value: 'booking_reference'
        },
        { text: 'Type', value: 'booking_type' },
        { text: 'Spent Points', value: 'points' },
        { text: 'Price', value: 'price_amount' },
        { text: 'Points per Dollar', value: 'points_per_unit_price' }
      ],
      pointsHeaders: [
        {
          text: 'Booking Reference.',
          align: 'start',
          sortable: false,
          value: 'booking_reference'
        },
        { text: 'Type', value: 'book_type' },
        { text: 'Earned Points', value: 'points' },
        { text: 'Description', value: 'description' },
        { text: 'Date', value: 'date' }
      ],
      dialog: false,
      pnrDialog: false,
      details: {},
      loading: false,
      loaded: true,
      valid: true,
      alert: false,
      color: '',
      text: '',
      name: '',
      phone: '',
      disableCancelBtn: false,
      tableLoad: false,
      emailToAdd: '',
      passwordDialog: false,
      passwords: {
        current_password: '',
        new_password: ''
      },
      showPassword: false,
      changePasswordLoad: false
    }
  },
  methods: {
    assignPhone (phone) {
      this.phone = phone
    },
    showMail (pnr) {
      this.$http.get(showPNR(pnr), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.status === true) {
          this.details = response.body.data
          this.pnrDialog = true
        } else {
          this.alert = true
          this.color = 'error'
          this.text = response.body.message
        }
      }, () => {
        this.alert = true
        this.color = 'error'
        this.text = 'Something went wrong please try again in few seconds'
      })
    },
    getThisDate (date) {
      const givenDate = date
      const [year, month, day] = givenDate.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return `${day} ${months[month - 1]} ${year}`
    },
    openDialog () {
      this.dialog = true
    },
    update () {
      this.loading = true
      this.$refs.form.validate()
      if (this.valid) {
        if (this.phone.valid) {
          const body = {
            email: this.user.email,
            name: this.name,
            phone: this.phone.nationalNumber,
            countryIsoCode: this.phone.countryCode
          }
          this.$http.post(userUpdate, body, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
            if (response.body.status === false) {
              this.alert = true
              this.color = 'error'
              this.text = response.body.message
              this.loading = false
              this.dialog = false
            } else {
              this.alert = true
              this.color = 'success'
              this.text = 'Your profile has been updated successfully'
              this.loading = false
              this.dialog = false
              this.$store.dispatch('setUser', this.user)
              this.user.name = this.name
              this.user.phone = this.phone.formatted
            }
          })
        } else {
          this.alert = true
          this.color = 'error'
          this.text = 'Please provide a valid mobile number'
          this.loading = false
          this.dialog = false
        }
      }
    },
    changePassword () {
      this.changePasswordLoad = true
      this.$http.post(changePassword, this.passwords, { headers: headers(this.$cookies.get('userToken')) }).then((response) => {
        this.alert = true
        if (response.body.status) {
          this.color = 'success'
          this.text = 'Password was updated successfully'
        } else {
          this.color = 'error'
          this.text = response.body.message
        }
      }).catch((err) => {
        this.alert = true
        this.color = 'error'
        this.text = err.body.message
      }).finally(() => {
        this.changePasswordLoad = false
        this.passwordDialog = false
      })
    },
    manageEmails (type, email) {
      if ((this.emailToAdd && type === 'add') || type === 'remove') {
        console.log(this.user.subAgent.agents_emails)
        const emails = this.user.subAgent.agents_emails
        if (type === 'add') emails.push(this.emailToAdd)
        else emails.splice(emails.indexOf(email), 1)
        this.$http.post(mangeAgentEmails, { emails: emails }, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
          this.alert = true
          this.color = 'success'
          this.text = 'Emails Updated'
          this.user.subAgent.agents_emails = response.body.data
          this.$store.dispatch('setUser', this.user)
        }).catch(err => {
          this.alert = true
          this.color = 'error'
          this.text = err.body.message
        }).finally(() => {
          this.emailToAdd = ''
        })
      } else {
        this.alert = true
        this.color = 'warning'
        this.text = 'Please add email at first'
      }
    },
    cancelFlight (flight) {
      this.disableCancelBtn = true
      this.tableLoad = true
      this.$http.post(cancelFlight, { pnr: flight.booking_reference }, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        this.disableCancelBtn = false
        this.tableLoad = false
        if (response.body.status) {
          this.alert = true
          this.color = 'success'
          this.text = 'Flight cancelled'
          this.flightBookings.forEach(item => {
            if (item.booking_reference === flight.booking_reference) {
              item.status = 'Cancelled'
            }
          })
        } else {
          this.alert = true
          this.color = 'error'
          this.text = response.body.message
        }
      }, err => {
        this.disableCancelBtn = false
        this.alert = true
        this.color = 'error'
        this.text = err.body.message
      })
    },
    getBookings () {
      this.$http.get(userBookings, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.data.FlightsBookings) this.flightBookings = response.body.data.FlightsBookings
        if (response.body.data.HotelsBookings) this.hotelsBookings = response.body.data.HotelsBookings
        if (response.body.data.CarBookings) this.carBookings = response.body.data.CarBookings
      })
    }
  },
  beforeMount () {
    if (!this.$cookies.isKey('userToken')) {
      this.$router.push({ name: 'login' })
    } else {
      let term = ''
      term = ''
      this.$http.get(profile(term), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        this.user = response.body.data
        if (this.user.subAgent) this.user.subAgent.agents_emails = [...new Set(this.user.subAgent.agents_emails)]
        this.name = response.body.data.name
        this.phone = response.body.data.phone
        this.loaded = false
      })
      this.getBookings()
      // if (this.$store.state.user.role === 'customer') {
      //   this.$http.get(redeem, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      //     this.redeem = response.body.data
      //   })
      //   this.$http.get(collectPoints, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      //     this.points = response.body.data
      //   })
      // }
    }
  }
}
</script>

<style>
  [v-cloak] { display: none; }
</style>
