<template>
    <v-container>
        <div class="d-flex flex-wrap justify-space-between">
            <h1 class="blueDark--text mb-5">Airline Code: {{$route.params.id}}</h1>
        </div>
        <div v-if="!loadingWaivers && waivers.length" class="d-flex flex-wrap">
            <v-card @click="showWaiverFile(item)" class="ma-1" v-for="item in waivers" :key="item.id">
                <v-card-title class="blueDark--text"><v-icon left color="orange">mdi-file-document</v-icon>{{item.name}}</v-card-title>
                <v-card-text><span v-for="code in item.airline_codes" :key="code">{{code}}, </span></v-card-text>
            </v-card>
        </div>
        <div v-else-if="!loadingWaivers && !waivers.length">
            <p class="headline my-10 text-center"><v-icon>mdi-alert</v-icon> No waivers found!</p>
        </div>
        <v-dialog width="fit-content" v-model="showImage">
            <v-card max-width="700">
                <v-img :src="currentImageUrl"></v-img>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { getWaivers, headers } from '../../../links'

export default {
  data () {
    return {
      waivers: [],
      loadingWaivers: true,
      showImage: false,
      currentImageUrl: ''
    }
  },
  methods: {
    showWaiverFile (item) {
      const imageFormats = ['jpg', 'jpeg', 'png']
      if (imageFormats.includes(item.name.split('.')[1])) {
        this.currentImageUrl = item.file
        this.showImage = true
      } else {
        this.$router.push({ name: 'waiverShow', query: { url: item.file } })
      }
    }
  },
  created () {
    this.$store.dispatch('setLoadingState', true)
    this.$http.get(getWaivers(this.$route.params.id), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      console.log(response)
      this.waivers = response.body.data
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.loadingWaivers = false
      this.$store.dispatch('setLoadingState', false)
    })
  }
}
</script>
