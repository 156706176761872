<template>
    <div>
        <div v-if="loading && !docReady" class="text-center">
            <v-progress-circular
            :size="50"
            color="blueDark"
            indeterminate
            class="my-15"
            ></v-progress-circular>
        </div>
        <p v-else-if="!loading && !docReady" class="my-15 text-center blueDark--text body-1">Couldn't download the file, please try again!</p>
        <iframe v-show="!loading && docReady" id="i_frame" height="1500" width="100%" scrolling="auto"
        :src="$route.query.url.split('.')[$route.query.url.split('.').length - 1] === 'pdf' ? $route.query.url : `https://docs.google.com/gview?url=${$route.query.url}&embedded=true`"
        ></iframe>
    </div>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      docReady: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.loading = false
    }, 5000)
    const iframe = document.getElementById('i_frame')
    if (iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
      if (iframeDoc.readyState === 'complete') {
        this.loading = false
        this.docReady = true
      }
    }
  }
}
</script>
