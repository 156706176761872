<template>
    <div>
        <v-snackbar app top right dark :timeout="4000" :value="snackbar" :color="color" class="text-center">
            {{ text }}
        </v-snackbar>
        <v-container>
            <v-card>
                <v-card-title class="d-flex justify-space-between indigo--text">
                    PNRs
                    <v-btn v-if="user.role === 'ats_admin'" class="elevation-0" tile dark color="indigo" @click="addPnrDialog = true">Add PNR</v-btn>
                </v-card-title>
                <v-tabs v-model="tab" color="indigo" align-with-title>
                  <v-tabs-slider class="ml-1" color="indigo"></v-tabs-slider>
                  <v-tab class="ml-1">Open PNRs</v-tab>
                  <v-tab v-if="user.role === 'ats_admin'">Reserved PNRs</v-tab>
                  <v-tab v-if="user.role === 'ats_admin'">Archived PNRs</v-tab>
                </v-tabs>
                <v-divider></v-divider>
                <v-card-text>
                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="pnrs"
                      :items-per-page="20"
                      class="elevation-1"
                      color="indigo"
                      >
                          <template v-slot:item.pnr="{ item }">
                            <router-link class="blue--text" :to="`/group-pnr/${item.id}`">{{ item.pnr }}</router-link>
                          </template>
                          <template v-slot:item.status>
                            Open
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <v-btn :disabled="loadingRemove" text color="error" @click="deletePNR('open', item.id)">
                              <v-icon>mdi-trash-can</v-icon> Remove
                            </v-btn>
                          </template>
                      </v-data-table>
                    </v-tab-item>
                    <v-tab-item v-if="user.role === 'ats_admin'">
                      <v-data-table
                      :loading="loadingReserved"
                      :headers="headers"
                      :items="reserved"
                      :items-per-page="20"
                      class="elevation-1"
                      color="indigo"
                      >
                        <template v-slot:item.pnr="{ item }">
                          <router-link class="blue--text" :to="`/group-pnr/${item.id}`">{{ item.pnr }}</router-link>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn :disabled="loadingRemove" text color="error" @click="deletePNR('reserved', item.id)">
                            <v-icon left>mdi-trash-can</v-icon> Remove
                          </v-btn>
                          <v-btn :disabled="loadingRemove" text color="info" @click="openUpdateDialog('reserved', item)">
                            <v-icon left>mdi-pencil</v-icon> update Status
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-tab-item>
                    <v-tab-item v-if="user.role === 'ats_admin'">
                      <v-data-table
                      :loading="loadingArchived"
                      :headers="headers"
                      :items="archived"
                      :items-per-page="20"
                      class="elevation-1"
                      color="indigo"
                      >
                        <template v-slot:item.pnr="{ item }">
                          <router-link class="blue--text" :to="`/group-pnr/${item.id}`">{{ item.pnr }}</router-link>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn :disabled="loadingRemove" text color="error" @click="deletePNR('archived', item.id)">
                            <v-icon left>mdi-trash-can</v-icon> Remove
                          </v-btn>
                          <v-btn :disabled="loadingRemove" text color="info" @click="unArchive(item)"> Unarchive
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
            </v-card>

            <!-- add pnr -->
            <v-dialog v-model="addPnrDialog" class="ma-5" max-width="700">
                <v-card max-width="700">
                    <v-card-title class="indigo--text">Add PNR</v-card-title>
                    <v-card-text>
                        <v-form v-model="validForm" ref="pnrForm">
                            <v-text-field outlined v-model="pnr.pnr" :rules="[v => !!v || 'PNR is required!']" label="PNR" color="blue"></v-text-field>
                            <strong>PNR Details</strong>
                            <ckeditor editorUrl="https://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js" v-model="pnr.pnr_details"></ckeditor>
                            <v-divider class="my-3"></v-divider>
                            <strong>Pricing</strong>
                            <v-text-field suffix="$" outlined class="mt-3" v-model="pnr.base_fare" type="number" min="0" :rules="[v => !!v || 'Base Fare is required!']" label="Base Fare" color="blue"></v-text-field>
                            <v-text-field suffix="$" outlined v-model="pnr.estimated_taxes" type="number" min="0" :rules="[v => !!v || 'Tax is required!']" label="Estimated Taxes" color="blue"></v-text-field>
                            <v-text-field suffix="$" outlined v-model="pnr.deposit" type="number" min="0" :rules="[v => !!v || 'Deposit is required!']" label="Deposit" color="blue"></v-text-field>
                            <v-divider class="mb-3"></v-divider>
                            <strong>Dates</strong>
                            <v-text-field outlined class="mt-3" v-model="pnr.name_list_due_date" :rules="[v => !!v || 'Name List Due Date is required!']" label="Name List Due Date" color="blue"></v-text-field>
                            <v-text-field outlined v-model="pnr.final_name_list" :rules="[v => !!v || 'Final Name List is required!']" label="Final Name List" color="blue"></v-text-field>
                            <v-text-field outlined v-model="pnr.ticketing_due_date" :rules="[v => !!v || 'Ticketing Due Date is required!']" label="Ticketing Due Date" color="blue"></v-text-field>
                            <v-divider class="mb-3"></v-divider>
                            <strong>Terms & Conditions</strong>
                            <ckeditor editorUrl="https://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js" v-model="pnr.terms"></ckeditor>
                            <strong>Penalties</strong>
                            <ckeditor editorUrl="https://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js" v-model="pnr.penalties"></ckeditor>
                            <strong>Deviations</strong>
                            <ckeditor editorUrl="https://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js" v-model="pnr.deviations"></ckeditor>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="elevation-0 px-10" tile dark color="indigo" :loading="loadingPnrs" @click="savePnr">Save pnr</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- update reserved pnrs status -->
            <v-dialog v-model="updateDialog" class="ma-5" max-width="700">
              <v-card max-width="700">
                <v-card-title class="indigo--text">Update PNR Status</v-card-title>
                <v-card-text>
                  <v-select v-model="pnrToEdit.status" label="PNR Status" :items="['Open', 'Pending', 'Deposit', 'Issued', 'Modification', 'Cancel', 'Archive', 'Confirmed', 'Reserved']" outlined></v-select>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0 px-10" tile dark color="indigo" :disabled="!pnrToEdit.status" :loading="loadingUpdateStatus" @click="updateStatus()">update pnr status</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import { groupPnrs, reservedPnrs, archivedPnrs, createPnr, removePnr, updatePnrStatus, headers } from '../../links'

export default {
  data () {
    return {
      tab: null,
      pnrs: [],
      reserved: [],
      archived: [],
      headers: [
        { text: 'PNR', align: 'start', value: 'pnr' },
        { text: 'Base Fare $', value: 'base_fare' },
        { text: 'Estimated Taxes $', value: 'estimated_taxes' },
        { text: 'Deposit $', value: 'deposit' },
        { text: 'Status', value: 'status' }
      ],
      pnr: {
        pnr: '',
        pnr_details: '',
        base_fare: 0,
        estimated_taxes: 0,
        deposit: 0,
        name_list_due_date: '',
        final_name_list: '',
        ticketing_due_date: '',
        terms: '',
        penalties: '',
        deviations: ''
      },
      validForm: true,
      loading: true,
      loadingReserved: true,
      loadingArchived: true,
      loadingPnrs: false,
      loadingRemove: false,
      addPnrDialog: false,
      pnrToEdit: {
        list: '',
        status: '',
        id: ''
      },
      updateDialog: false,
      loadingUpdateStatus: false,
      snackbar: false,
      color: '',
      text: ''
    }
  },
  methods: {
    savePnr () {
      this.$refs.pnrForm.validate()
      if (this.validForm) {
        this.loadingPnrs = true
        this.$http.post(createPnr, this.pnr, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
          console.log(response)
          if (response.body.status) {
            this.pnrs.push(response.body.data)
            this.addPnrDialog = false
            this.$refs.pnrForm.reset()
            this.snackbar = true
            this.color = 'success'
            this.text = response.body.message
          } else {
            this.snackbar = true
            this.color = 'error'
            this.text = response.body.message
          }
        }).catch(err => {
          console.log(err, err.response)
          this.snackbar = true
          this.color = 'error'
          this.text = err.response.message
        }).finally(() => {
          this.loadingPnrs = false
        })
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please fill all fields!'
      }
    },
    deletePNR (list, id) {
      this.loadingRemove = true
      this.$http.delete(removePnr(id), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.status === true || response.body.status === 200) {
          switch (list) {
            case 'open':
              this.pnrs.splice(this.pnrs.findIndex(a => a.id === id), 1)
              break
            case 'reserved':
              this.reserved.splice(this.reserved.findIndex(a => a.id === id), 1)
              break
            case 'archived':
              this.archived.splice(this.archived.findIndex(a => a.id === id), 1)
              break
            default:
              break
          }
          this.snackbar = true
          this.color = 'success'
          this.text = 'PNR was deleted successfully!'
        } else {
          this.snackbar = true
          this.color = 'error'
          this.text = response.body.message
        }
      }).catch(err => {
        console.log(err, err.response)
        this.snackbar = true
        this.color = 'error'
        this.text = err.response.message
      }).finally(() => {
        this.loadingRemove = false
      })
    },
    openUpdateDialog (type, pnr) {
      this.pnrToEdit.status = pnr.status.charAt(0).toUpperCase() + pnr.status.slice(1)
      this.pnrToEdit.id = pnr.id
      this.pnrToEdit.list = type
      this.updateDialog = true
    },
    unArchive (pnr) {
      this.pnrToEdit.status = 'open'
      this.pnrToEdit.id = pnr.id
      this.pnrToEdit.list = 'archived'
      this.updateStatus()
    },
    updateStatus () {
      this.loadingUpdateStatus = true
      this.loadingRemove = true
      this.$http.post(updatePnrStatus(this.pnrToEdit.id), { status: this.pnrToEdit.status }, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.status === 200) {
          this.getOpenPnrs()
          this.getArchivedPnrs()
          this.getReservedPnrs()
          this.snackbar = true
          this.color = 'success'
          this.text = 'PNR status was updated successfully!'
          this.updateDialog = false
        } else {
          this.snackbar = true
          this.color = 'error'
          this.text = response.body.message
        }
      }).catch(err => {
        this.snackbar = true
        this.color = 'error'
        this.text = err.response.message
      }).finally(() => {
        this.loadingUpdateStatus = false
        this.loadingRemove = false
      })
    },
    getOpenPnrs () {
      this.$http.get(groupPnrs, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.status === true || response.body.status === 200 || response.body.status === 204 || response.body.status === 404) {
          this.pnrs = response.body.data
        }
      }).catch(() => {
        this.pnrs = []
      }).finally(() => {
        this.loading = false
      })
    },
    getReservedPnrs () {
      this.$http.get(reservedPnrs, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.status === true || response.body.status === 200 || response.body.status === 204 || response.body.status === 404) {
          this.reserved = response.body.data
        }
      }).catch(() => {
        this.reserved = []
      }).finally(() => {
        this.loadingReserved = false
      })
    },
    getArchivedPnrs () {
      this.$http.get(archivedPnrs, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.status === true || response.body.status === 200 || response.body.status === 204 || response.body.status === 404) {
          this.archived = response.body.data
        }
      }).catch(() => {
        this.archived = []
      }).finally(() => {
        this.loadingArchived = false
      })
    }
  },
  created () {
    this.user = this.$store.state.user
    if (this.user.role === 'ats_admin') {
      this.headers.push({ text: 'Actions', value: 'actions' })
      this.getReservedPnrs()
      this.getArchivedPnrs()
    }
    this.getOpenPnrs()
  }
}
</script>
