<template>
    <v-card>
        <v-simple-table color="blueDark">
            <template v-slot:default>
            <thead class="contract-table-head">
                <tr>
                    <th class="text-left white--text body-1 font-weight-bold">
                        Order
                    </th>
                    <th class="text-left white--text body-1 font-weight-bold">
                        Airlines
                    </th>
                    <th class="text-left white--text body-1 font-weight-bold">
                        Contract Number
                    </th>
                    <th class="text-left white--text body-1 font-weight-bold">
                        Name
                    </th>
                    <th class="text-left white--text body-1 font-weight-bold">
                        Contract Group
                    </th>
                    <th v-if="$store.state.user.role === 'ats_admin'" class="text-left white--text body-1 font-weight-bold">
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in contracts" :key="item.id">
                    <td class="clickable-field" @click="$router.push({name: 'contractDetails', params: {id: item.id}})">{{ item.order }}</td>
                    <td>
                        <span v-for="(airline, i) in item.airlineCodes" :key="'airline_code'+i">
                            <span v-if="airline.airline_code">{{ airline.airline_code + ', ' }}</span>
                            <span v-else>{{ airline + ', ' }}</span>
                        </span>
                    </td>
                    <td>{{ item.contract_number ? item.contract_number : '----' }}</td>
                    <td class="clickable-field" @click="$router.push({name: 'contractDetails', params: {id: item.id}})">{{ item.name }}</td>
                    <td v-if="item.region">{{ item.region.name }}</td>
                    <td v-else>---</td>
                    <td>
                        <v-btn v-if="$route.name === 'deletedContracts' && $store.state.user.role === 'ats_admin'" text color="blueDark" @click="restoreContract(item.id)"><v-icon left>mdi-file-restore</v-icon> restore</v-btn>

                        <v-btn v-else-if="$route.name !== 'deletedContracts' && $store.state.user.role === 'ats_admin'" text color="blueDark" @click="$router.push({name: 'editContract', params: {id: item.id}})"><v-icon left>mdi-file-document-edit</v-icon> Edit</v-btn>
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
import { restoreDeleteContract, headers } from '@/links.js'

export default {
  props: ['contracts'],
  data () {
    return {}
  },
  methods: {
    restoreContract (id) {
      this.$http.get(restoreDeleteContract(id), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        this.$emit('restored')
      }).catch(err => {
        this.$emit('error', err.body.message)
      })
    }
  }
}
</script>

<style scoped>
    .contract-table-head {
        background-color: #202847;
    }
    .clickable-field {
        cursor: pointer
    }
    .clickable-field:hover {
        color: #FDB71A;
    }
</style>
