<template>
  <v-container class="text-center">
    <h1 class="my-5 blueDark--text">
      Contract DOT / Airline Code:
      <span class="blueLight--text">{{$route.params.id}}</span>
    </h1>
    <div v-if="!loading && contract">
      <img width="80%" :src="contract.url" />
    </div>
    <div v-else-if="!loading && !contract">
      <p class="headline my-10 text-center">
        <v-icon color="warning">mdi-alert</v-icon>
         contract information isn't available!
      </p>
    </div>
    <div v-else class="text-center my-15 d-flex justify-center align-center">
      <v-progress-circular indeterminate color="blueLight"></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import { contractDOT, headers } from '../../links'

export default {
  name: 'contractDOT',
  data () {
    return {
      contract: null,
      loading: true
    }
  },
  mounted () {
    this.$http.get(contractDOT(this.$route.params.id), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      console.log(response.body.data)
      if (response.body.data?.length) {
        this.contract = response.body.data[0]
      }
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>
