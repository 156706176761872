<template>
  <v-container>
      <v-snackbar
      :color="color"
      :timeout="3000"
      top
      v-model="alert"
      >
        {{text}}
        <v-icon small color="white" @click="alert = false">mdi-close</v-icon>
      </v-snackbar>
      <v-row style="min-height: 80vh" class="auth-form-cont" justify="center" align="center">
          <v-col cols="12">
              <v-card>
                  <v-card-title
                  class="headline secondary white--text font-weight-regular"
                  primary-title
                  >
                  Join Us Now
                  </v-card-title>
                  <v-form
                  ref="form"
                  v-model="valid"
                  v-on:submit.prevent
                  >
                      <v-card-text>
                          <v-row>
                              <v-col cols="12" md="6">
                                  <v-text-field
                                  label="Full Name"
                                  v-model="payload.name"
                                  outlined
                                  prepend-inner-icon="mdi-account"
                                  :rules="[v => (!!v && v.length > 3) || 'Item is required at least 3 characters', v => /^[_A-z]*((-|\s)*[_A-z])*$/.test(v) || 'Name Must be letters only with no spaces']"
                                  color="blue"
                                  id="user-fullName"
                                  >
                                  </v-text-field>
                              </v-col>

                              <v-col cols="12" md="6">
                                  <v-text-field
                                  label="Email"
                                  v-model="payload.email"
                                  type="email"
                                  prepend-inner-icon="mdi-email"
                                  :rules="[v => !!v || 'Email is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                                  color="blue"
                                  outlined
                                  id="user-email"
                                  >
                                  </v-text-field>
                              </v-col>

                              <v-col cols="12" md="6">
                                  <phoneInput @update="assignPhone" />
                              </v-col>

                              <v-col cols="12" md="6">
                                  <v-text-field
                                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                  label="Password"
                                  v-model="payload.password"
                                  :type="show ? 'text' : 'password'"
                                  @click:append="show = !show"
                                  prepend-inner-icon="mdi-lock"
                                  :rules="[v => !!v || 'Password is required', v => v.length >= 8 || 'Password must be at least 8 characters']"
                                  color="blue"
                                  outlined
                                  id="user-password"
                                  >
                                  </v-text-field>
                              </v-col>

                              <v-col cols="12" md="6">
                                  <v-text-field
                                  label="Agency Name"
                                  v-model="payload.agencyName"
                                  outlined
                                  prepend-inner-icon="mdi-domain"
                                  :rules="[v => !!v || 'Item is required']"
                                  color="blue"
                                  id="agency-name"
                                  >
                                  </v-text-field>
                              </v-col>

                              <v-col cols="12" md="6">
                                <v-select
                                :items="offices"
                                item-value="id"
                                item-text="name"
                                outlined
                                label="Choose Office"
                                color="blue"
                                prepend-inner-icon="mdi-office-building"
                                v-model="payload.office_id"
                                :rules="[v => !!v || 'Item is required']"
                                ></v-select>
                              </v-col>

                              <v-col cols="12" md="6">
                                <v-select
                                :items="['airfares', 'commission']"
                                outlined
                                label="Registeration Type"
                                placeholder="Choose Registeration Type"
                                color="blue"
                                :rules="[v => !!v || 'Item is required']"
                                prepend-inner-icon="mdi-badge-account-horizontal"
                                v-model="payload.registrationType"
                                ></v-select>
                            </v-col>

                              <v-col cols="12" md="6">
                                  <v-text-field
                                  label="Agency Address"
                                  v-model="payload.address"
                                  outlined
                                  prepend-inner-icon="mdi-map-marker"
                                  :rules="[v => !!v || 'Item is required']"
                                  color="blue"
                                  id="agency-name"
                                  >
                                  </v-text-field>
                              </v-col>

                              <!-- <v-col cols="12" v-if="payload.registrationType !== 'commission'">
                                <v-checkbox color="blue" v-model="payload.offices_check" label="I currently work with adam travel office"></v-checkbox>
                              </v-col> -->

                              <!-- <v-col cols="12" md="6" v-if="payload.registrationType !== 'commission'">
                                <v-select
                                :items="offices"
                                outlined
                                label="Choose GDS Office"
                                color="blue"
                                item-value="id"
                                item-text="name"
                                prepend-inner-icon="mdi-office-building"
                                v-model="payload.office_id"
                                :disabled="!payload.offices_check"
                                ></v-select>
                              </v-col> -->

                              <template v-if="showFields">
                                <v-col cols="12" md="6">
                                  <v-text-field
                                  label="IATA Number"
                                  v-model="payload.iata_number"
                                  outlined
                                  color="blue"
                                  >
                                  </v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                  <v-text-field
                                  label="EIN"
                                  v-model="payload.ein"
                                  outlined
                                  color="blue"
                                  >
                                  </v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                  <v-text-field
                                  label="GDS Number"
                                  v-model="payload.gds_number"
                                  outlined
                                  color="blue"
                                  >
                                  </v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                  <v-select
                                  :items="['Less than 100k', '100k - 500k', '500k - 1m', 'More than 1m']"
                                  outlined
                                  label="Average Sales"
                                  color="blue"
                                  v-model="payload.avg_sales"
                                  ></v-select>
                                </v-col>

                                <v-col cols="12" md="6">
                                  <v-text-field
                                  outlined
                                  prepend-inner-icon="mdi-web"
                                  v-model="payload.url"
                                  label="Agency Website"
                                  color="blue"
                                  :rules="[(value) => this.isURL(value) || 'URL is not valid']"
                                  >
                                  </v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                  <v-text-field
                                  label="How did you know about us ?"
                                  v-model="payload.how_know_us"
                                  outlined
                                  prepend-inner-icon="mdi-share-variant"
                                  color="blue"
                                  >
                                  </v-text-field>
                                </v-col>
                              </template>
                          </v-row>
                      </v-card-text>
                      <v-card-actions>
                          <router-link :to="{ name: 'login'}" class="blue--text"><span> Login instead</span></router-link>
                          <v-spacer></v-spacer>
                          <v-btn type="submit" tile color="secondary" @click="submit" :disabled="!valid" :loading="loading">Submit</v-btn>
                      </v-card-actions>
                  </v-form>
              </v-card>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import { register, offices, headersNoAuth } from '../links'
import phoneInput from '../components/mobile.vue'

export default {
  title: '-Register',
  components: {
    phoneInput
  },
  data () {
    return {
      alert: false,
      color: '',
      text: '',
      show: false,
      show1: false,
      valid: true,
      loading: false,
      payload: {
        name: '',
        email: '',
        phone: '',
        countryIsoCode: '',
        password: '',
        registrationType: '',
        address: '',
        url: '',
        agencyName: '',
        offices_check: true,
        office_id: '',
        how_know_us: '',
        iata_number: '',
        atn_office: ''
      },
      offices: [],
      phone: null,
      agreed: false
    }
  },
  computed: {
    showFields () {
      return this.payload.registrationType === 'airfares'
    }
  },
  methods: {
    isURL (str) {
      if (str) {
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }
        return url.protocol === 'http:' || url.protocol === 'https:'
      } else return true
    },
    assignPhone (phone) {
      this.phone = phone
    },
    submit () {
      this.loading = true
      this.$refs.form.validate()
      if (this.valid) {
        if (this.phone.valid) {
        // const body = {
        //   name: this.name,
        //   email: this.email,
        //   password: this.password,
        //   phone: this.phone.nationalNumber,
        //   countryIsoCode: this.phone.countryCode
        // }
          this.payload.phone = this.phone.nationalNumber
          this.payload.countryIsoCode = this.phone.countryCode
          this.$http.post(register, this.payload, { headers: headersNoAuth() }).then(response => {
            if (response.body.status === false) {
              this.alert = true
              this.color = 'error'
              this.text = response.body.message
              this.loading = false
            } else {
              this.$router.push({ name: 'verify', params: { email: this.payload.email } })
              this.loading = false
            }
          })
        } else {
          this.alert = true
          this.color = 'error'
          this.text = 'Please provide a valid mobile number'
          this.loading = false
        }
      }
    }
  },
  created () {
    this.$http.get(offices, { headers: headersNoAuth() }).then(response => {
      this.offices = response.data.data
    }).catch()
  },
  beforeCreate () {
    if (this.$cookies.isKey('userToken')) this.$router.push({ name: 'profile' })
  }
}
</script>

<style>
/* .auth-form-cont {
min-height: 90vh;
} */
.remove-controls input[type='number'] {
  -moz-appearance:textfield;
}
.remove-controls input::-webkit-outer-spin-button,
.remove-controls input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
