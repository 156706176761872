<template>
  <v-app class="app-body">
    <navbar/>
    <v-main>
      <div class="text-center">
        <v-progress-circular
        v-if="showLoading"
        :size="70"
        :width="7"
        color="purple"
        indeterminate
        class="mx-auto my-15"
        ></v-progress-circular>
      </div>

      <router-view></router-view>
    </v-main>
    <footerBar v-if="$route.name !== 'approve'" />
  </v-app>
</template>

<script>
import navbar from './components/navbar.vue'
import footerBar from './components/footer.vue'
import { profile, headers } from './links'

export default {
  name: 'App',
  components: {
    navbar,
    footerBar
  },
  data () {
    return {
      version: '2.1'
    }
  },
  computed: {
    showLoading () {
      return this.$store.state.showLoading
    },
    showNav () {
      return this.$route.name !== 'approve' && this.$route.name !== 'login' && this.$route.name !== 'verify' && this.$route.name !== 'resetPassword'
    }
  },
  mounted () {
    if (this.$store.state.version !== this.version) {
      this.$store.dispatch('removeAirportsData')
      this.$store.dispatch('removeDatesData')
      this.$store.dispatch('removePassengersData')
      this.$store.dispatch('setPreferredAirline', null)
      this.$store.dispatch('setVersion', this.version)
    }
    // this.$store.dispatch('setUserMails', this.users)
    if (this.$cookies.isKey('userToken')) {
      let term = ''
      term = ''
      this.$http.get(profile(term), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.status === true) {
          this.$store.dispatch('setUser', response.body.data)
          localStorage.setItem('atsUser', JSON.stringify(response.body.data))
        } else {
          this.$store.dispatch('removeUser')
          this.$cookies.remove('userToken')
          localStorage.removeItem('userId')
          localStorage.removeItem('token')
        }
      })
    }
    // this.$http.get(homeTrips, { headers: headersNoAuth() }).then(response => {
    //   this.$store.dispatch('setHomeTrips', response.body.data)
    // })
  },
  created () {
    if (localStorage.getItem('token')) this.$cookies.set('userToken', localStorage.getItem('token'), '1d')
    this.$http.interceptors.push((request, next) => {
      next(response => {
        if (response.status === 401) {
          this.$store.dispatch('removeUser')
          this.$cookies.remove('userToken')
          localStorage.removeItem('userId')
          localStorage.removeItem('token')
          this.$router.push({ name: 'login' })
        }
      })
    })
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault()
      this.$store.dispatch('removeCountryCode')
      this.$store.dispatch('removeGuestData')
    })
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');
  .v-application {
    max-width: 100vw;
  }

  .font-header {
    font-family: 'Playball', cursive;
    font-size: 31px;
  }
</style>
