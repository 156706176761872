import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueResource from 'vue-resource'
import titleMixin from './mixins/titleMixin'
import VueCookies from 'vue-cookies'
import * as VueGoogleMaps from 'vue2-google-maps'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
// import VueFbCustomerChat from 'vue-fb-customer-chat'
import { currencies } from './links.js'
import ReadMore from 'vue-read-more'

import CKEditor from 'ckeditor4-vue'

const config = {
  toolbar: [
    'removeFormat', 'undo', '|', 'elements', 'fontName', 'fontSize', 'foreColor', 'backColor'
  ],
  fontName: [
    { val: 'arial black' },
    { val: 'times new roman' },
    { val: 'Courier New' }
  ],
  fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
  uploadUrl: ''
}

Vue.use(CKEditor, config)
Vue.use(ReadMore)

Vue.prototype.window = window

Vue.config.productionTip = false

const url = currencies
fetch(url).then(response => response.json()).then(response => {
  if (response.status) {
    store.dispatch('setCurrencies', response.data)
  }
  // if (process.env.VUE_APP_DEFAULT_CURRENCY) {
  //   store.dispatch('setCurrency', response.data.find(item => item.code === process.env.VUE_APP_DEFAULT_CURRENCY))
  // }
})

Vue.filter('changeCurrency', function (price) {
  const defaultRate = store.state.currencies.find(item => item.code === 'USD')
  // const defaultRate = store.state.currency
  const finalPrice = (price / defaultRate.rate) * store.state.currency.rate
  return store.state.currency.code + ' ' + finalPrice.toFixed(2)
})

Vue.filter('formatDate', function (date) {
  date.replace(/-/g, '/')
  return new Date(date).toDateString()
})

Vue.component('date-picker', DatePicker)

Vue.use(VueResource)

Vue.http.interceptors.push(request => {
  // modify timeout
  request.timeout = 30000
})

Vue.mixin(titleMixin)
Vue.use(VueCookies)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDrsBloKtM-pbhQ7_TMq7LraBv5NrAid98',
    libraries: 'places' // necessary for places input
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
